import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useMutation,
} from 'react-query'

import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'

function useCreateProcessEvidence() {
    const token = useJWTToken()

    return useMutation<any>((process) => {
        return requestBusinessObjects({
            method: 'POST',
            url: 'shipmentprocess',
            token,
            data: process,
        })
            .then((data) => {
                return data.data
            })
    }, {
        retry: false,
    })
}

export default useCreateProcessEvidence
