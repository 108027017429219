import React from 'react'
import styles from "../PictureMultiple.module.scss";
import FieldTitle from "../../../FieldTitle";
import Button from "../../../Button";
import {useTranslation} from "react-i18next";


type Props = {
    onYes: any,
    onNo: any,
    yesText?: string,
    noText?:string,
    title?:string
}

const YesNoDialog = ({
                         onYes,
                         onNo
                     }: Props) => {
    const {t} = useTranslation()

    return <div
        className={styles.tabContent}
    >
        <div
            className={styles.container}
        >

            <div
                className={styles.content}
            >
                <div className={styles.title}>
                    <FieldTitle
                        title={t('TAKE_ANOTHER_PICTURE')}
                    />
                </div>
                <div className={styles.divider}/>
                <div className={styles.buttonBox}>
                    {
                        onNo && <Button
                            text={t('NO')}
                            onClick={onNo}
                        />
                    }
                    {
                        onYes && <Button
                            text={t('YES')}
                            onClick={onYes}
                        />
                    }

                </div>

            </div>
        </div>
    </div>

}

export default YesNoDialog
