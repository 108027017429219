import React, {useEffect, useMemo, useRef, useState} from 'react'
import Webcam from "react-webcam";
import useStyles from "./MultiReader.style";
import useEnumerateDevices from "../../hooks/useEnumerateDevices";
import {Constraints} from "../../utils/dataTypes";
import useQRReader from "../../hooks/useQRReader";
import useBarcodeReader from "../../hooks/useBarcodeReader";
import BarcodeScannerComponent from 'react-qr-barcode-scanner';
import getContainerNumberFromGRAI from "../../utils/getContainerNumberFromGRAI";
import SkipButton from "../SkipButton";


type Props = {
    onScan: any,
    onSkip: any,
    label?: string,
    skippable?: boolean
    rawInput?: boolean
}
const CAMERA_WIDTH = 320
const CAMERA_HEIGHT = 320

const MultiReader = ({
                         onScan = () => {
                         },
                         skippable = false,
                         rawInput = false,
                         onSkip,
                     }: Props) => {
    const classes = useStyles()
    const device = useEnumerateDevices()

    const videoConstraints = useMemo(() => {
        const constraints: any = {
            height: 1280,
            width: 720,
            aspectRatio: 1
        }

        if (device && device.facingMode) {
            constraints.facingMode = device.facingMode
        }

        return constraints
    }, [device])


    const handleCodeChange = (err, result) => {
        if (err) {
            return
        }

        const {text} = result
        let code

        if (!text || text.length === 0) {
            return;
        }


        code = text.length >= 10 ? (rawInput?text:getContainerNumberFromGRAI(text)) : text

        onScan(code)
    }
    return <>
        <div className={classes.wrapper}>
            <BarcodeScannerComponent
                videoConstraints={videoConstraints}
                width={CAMERA_WIDTH}
                height={CAMERA_HEIGHT}
                onUpdate={handleCodeChange}
            />

        </div>
        {
            skippable && (
                <SkipButton
                    onClick={onSkip}
                />
            )
        }

    </>


}

export default MultiReader
