import React, {useMemo} from "react";
import {AppMenuItem, AppMenuItemType} from "../../utils/dataTypes";
import {useStyles} from "./AppDrawerMenuItem.style";
import getGlobalAssetUrl from "../../utils/getGlobalAssetUrl";
import {useHistory,} from 'react-router-dom'
import { useLocation } from 'react-router-dom'

type Props = AppMenuItem & { onClose: Function }


const AppDrawerMenuItem = ({
                               label = null,
                               type = null,
                               children = null,
                               icon = null,
                               route = null,
                               onClick = null,
                               onClose = null
                           }: Props) => {

    const classes = useStyles()
    const history = useHistory()
    const { pathname } = useLocation()

    const redirect = useMemo(() => (route) => {
        if (onClick) {
            onClick()
            return
        }
        history.push("/")
        setImmediate(() => {
            history.push(route)
        })

        onClose && onClose()
    }, [])

    const active = useMemo<boolean>(() => {
        if(pathname === '/'){
            return route === '/'
        }
        return route?.startsWith(pathname)

    }, [pathname])

    return <>
        <div
            className={[
                classes.item,
                type === AppMenuItemType.PARENT ? classes.menuHeader : classes.menuItem,
                active?classes.active:''
            ].join(" ")}
            onClick={() => type === AppMenuItemType.ROUTE ? redirect(route) : {}}
        >
            {icon && <img
                className={classes.icon}
                src={getGlobalAssetUrl(icon)}
                alt={icon}
            />}
            <span>{label}</span>
        </div>
        {children ? children.map(child => <AppDrawerMenuItem key={`${child.label}_${child.type}`} {...child} onClose={onClose}/>) : ""}
    </>
}

export default AppDrawerMenuItem
