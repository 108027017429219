import {makeStyles} from "@material-ui/core";


export const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3),
        color: '#000000DE',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    welcome: {
        fontSize: 56
    },
    text: {
        fontSize: 36
    },
    textContainer: {
    },
    imageWrapper: {
        width: '100%',
        height: 'calc(50% - 96px)',
        display: "flex",
        justifyContent: 'center'
    },
    image: {
        width: '100%',
        maxWidth: '500px',
        objectFit: 'contain'
    },
    buttonHolder: {
        marginTop: 10,
        textAlign: 'center',
        paddingBottom: 50
    }
}))
