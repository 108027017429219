import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            color: '#000000DE'
        },
        tabContent: {},
        topScreen: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        containerText: {
            fontSize: '36px'
        },
        serialNumber: {
            fontSize: '56px'
        },
        temperatureTextWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',

        },
        temperatureTitle: {
            fontSize: '20px'
        },
        temperature: {},
        cardHolder: {},
        measurementCardHolder: {
            width: '90vw',
            maxWidth: 440
        },
        measurementCard: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            padding: 15,
            '& > div': {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                color: '#747474',
                marginBottom: 4,
                '& > span': {
                    font: 'normal normal normal 14px/17px Roboto'
                },
                '& > span:nth-child(1)': {
                    textAlign: 'right',
                    marginRight: 5,
                },
                '& > span:nth-child(2)': {
                    display: 'flex',
                    textAlign: 'left',
                    alignItems: 'center'
                },

            },

        },
        buttonsContainer: {
            maxWidth: '100vw'
        },
        buttonsRow: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '15px 5px',
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
            }
        },
        button: {
            width: 'auto',
            margin: '4px 3px',
            minWidth: 'unset',
            padding: '5px 6px'
        },
        buttonFullWidth: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',

        },
        shimpentCardHolder: {
            marginTop: 20,
            width: '90vw',
            maxWidth: 440,
            color: '#747474'
        },
        icon: {
            width: 16,
            height: 16,
            marginRight: 8
        },
        shipmentCardContainer: {
            '& *': {
                font: 'normal normal normal 14px/17px Roboto'
            }
        },
        shipmentCardTitle: {
            display: 'grid',
            padding: '13px 13px 3px 13px',
            gridTemplateColumns: '1fr 1fr',
            borderBottom: '1px solid #D7D7D7',
            color: '#747474',
            '& b': {
                marginRight: 5,
                fontFamily: 'Roboto Medium'
            },
            [theme.breakpoints.down('xs')]: {
                gridTemplateColumns: '1fr 1fr',
                paddingBottom: '7px'
            }
        },
        shipmentCardBody: {
            padding: '3px 13px 13px 13px',
            color: '#747474',
            display: 'grid',
            gridTemplateColumns: 'max-content 1fr',
            gridGap: '2px',

            '& > span:nth-child(2n+1)': {
                textAlign: 'right',
                marginRight: 5,
                fontFamily: 'Roboto Medium'
            },
            '& > span:nth-child(2n)': {
                display: 'flex',
                textAlign: 'left',
                alignItems: 'center',
                whiteSpace: 'pre-wrap'
            },


        },
        minMaxRange: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            width: '100%'
        },
        containerOfflineImage: {
            width: '80%'
        },
        containerOfflineText: {
            width: '100%',
            textAlign: 'center',
            fontSize: 36
        },
        green: {
            color: "#17921f"
        },
        orange: {
            color: "#edae49"
        },
        red: {
            color: "#D44848"
        },
        sleepingImg: {
            width: 'calc(100% - 50px)',
            maxWidth: '500px'
        },
        fontSize36: {
            fontSize: 36
        },
        fontSize96: {
            fontSize: 96
        },
        spanTooltip: {
            cursor: 'pointer',
            transition: '200ms ease',
            '&:hover': {
                color: theme.palette.primary[300]
            }
        }

    }
})

export default useStyles
