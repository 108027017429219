import React, {useMemo, useState} from 'react'
import useStyles from "../TemperatureInfo.style";
import {Card} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import getGlobalAssetUrl from "../../../../utils/getGlobalAssetUrl";
import {ICONS, TEMP_RANGE_CHECK, TEMPERATURE_RANGES, TemperatureIcons} from "../../../../utils/constants";
import moment from "moment";


type Props = {
    temperatureRange?: string
    tempRangeCheck?: TEMP_RANGE_CHECK,
    lastMeasuredTempInternal?: number
    lastMeasuredOnUtcInternal?: number
}

const rangeCheckIcons = {
    [TEMP_RANGE_CHECK.OK]: ICONS.checked_blue,
    [TEMP_RANGE_CHECK.NO_DATA]: ICONS.dots_round_grey,
    [TEMP_RANGE_CHECK.FAILED_TEMPERATURE]: ICONS.cross
}

const MeasurementCard = ({
                             temperatureRange = "CRT",
                             tempRangeCheck = TEMP_RANGE_CHECK.NO_DATA,
                             lastMeasuredTempInternal = null,
                             lastMeasuredOnUtcInternal = null
                         }: Props) => {
    const classes = useStyles()
    const {t} = useTranslation()
    const [minutesDiff, setMinutesDiff] = useState<number>(null)

    const lastMeasuredAgo = useMemo(() => {
        if(!lastMeasuredOnUtcInternal) return t("TEMPERATURE.NOT_MEASURED_YET")

        const duration = moment
            .duration(moment().utc(false)
                .diff(moment(lastMeasuredOnUtcInternal* 1000).utc(false)));
        const minutes = Math.ceil(Math.abs(duration.asMinutes()));
        const hours = Math.floor(minutes/60);
        const days = Math.floor(hours/24);
        setMinutesDiff(minutes);

        return (days ? `${days} ${t("TEMPERATURE.DAY"+ (days!==1?"S":""))} `:'') +
            (hours ? `${hours%24} ${t("TEMPERATURE.HOUR"+ (hours%24!==1?"S":""))} `:'') +
            (minutes?`${minutes%60} ${t("TEMPERATURE.MINUTE" + (minutes%60!==1?"S":"")) } `:'') +
            t("TEMPERATURE.AGO")

    }, [lastMeasuredOnUtcInternal])

    const lastMeasuredAgoColor = useMemo(() => {
        if (!minutesDiff || minutesDiff >=24 * 60) return classes.red
        if (minutesDiff >=60 && minutesDiff < 24* 60) return classes.orange
        if (minutesDiff < 60) return classes.green
        return ""
    }, [minutesDiff])

    const lastMeasuredAgoText = useMemo(() => {
        return lastMeasuredOnUtcInternal?
            <span className={classes.fontSize96}>{`${lastMeasuredTempInternal}°C`}</span>:
            <span className={classes.fontSize36}>{t("NOT_ASSIGNED")}</span>
    }, [lastMeasuredOnUtcInternal])

    return <>
        <div className={classes.cardHolder}>
            <div className={classes.temperatureTextWrapper}>
                <span className={classes.temperatureTitle}>{t('TEMPERATURE.INTERNAL_TEMPERATURE')}</span>
                <span className={classes.temperature}>
                        {lastMeasuredAgoText}
                    </span>

            </div>

            <div className={classes.measurementCardHolder}>
                <Card>
                    <div className={classes.measurementCard}>
                        <div>
                            <span>{t('TEMPERATURE.MEASURED')}</span>
                            <span
                                className={lastMeasuredAgoColor}
                            >{lastMeasuredAgo}</span>
                        </div>
                        <div>
                            <span>{t('TEMPERATURE.ALLOWED_RANGE')}</span>
                            <span>
                            <img
                                className={classes.icon}

                                src={getGlobalAssetUrl(TemperatureIcons[temperatureRange])}
                                alt="tempIcon"
                            />
                                {TEMPERATURE_RANGES[temperatureRange]}</span>
                        </div>
                        <div>
                            <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
                            <span>
                            <img
                                className={classes.icon}
                                src={getGlobalAssetUrl(rangeCheckIcons[tempRangeCheck])}
                                alt="check"
                            />
                                {temperatureRange === TEMP_RANGE_CHECK.NO_DATA
                                    && t("NO_DATA") || t("No Recent Data")}
                        </span>
                        </div>
                    </div>
                </Card>
            </div>
        </div>

    </>
}

export default MeasurementCard
