import React from 'react'
import Button from "../Button";
import useStyles from "../steps/Picture/Picture.style";
import {useTranslation} from "react-i18next";
import SkipButton from "../SkipButton";


type Props = {
    onTextChanged: (...args: any) => any,
    text: string,
    onNext: (...args: any) => any,
    onSkip?: (...args: any) => any,
}
const AddComment = ({
                        onTextChanged,
                        text = '',
                        onNext,
                        onSkip,
                    }: Props) => {

    const classes = useStyles()

    const {t} = useTranslation()

    return <div
        className={classes.tabContent}
    >
        <div
            className={classes.container}
        >

            <div
                className={classes.content}
            >
                <h2
                    className={classes.title}
                >
                    {t("ADD_COMMENT")}
                </h2>
            </div>

            <div className={classes.inputWrapper}>
                    <textarea
                        value={text}
                        className={classes.textArea}
                        onInput={(event) => {
                            onTextChanged(event.currentTarget.value)
                        }}
                    />
            </div>
            <div className={classes.buttonBox}>
                <Button
                    text={t("NEXT")}
                    disabled={!Boolean(text)}
                    onClick={onNext}
                />
                {
                    (onSkip && !text) && <SkipButton
                        onClick={onSkip}
                    />
                }

            </div>
        </div>
    </div>
}

export default AddComment
