import React, {
    useMemo,
} from 'react'
import {
    NavLink,
} from 'react-router-dom'
import {
    useTranslation,
} from 'react-i18next'
import {
    useUserRoles,
} from '@skycell-ag/auth'
import ErrorScreen from 'app/components/ErrorScreen'
import useFetchProcesses from 'app/hooks/useFetchProcesses'

import useStyles from './MainMenu.style'
import LoadingSkyCell from "../../components/LoadingSkyCell";
import useUserLocation from "../../hooks/useUserLocation";

export const SSI_PROCESS_EXECUTION = 'SSI_PROCESS_EXECUTION'

const FORBIDDEN_STATUS = 403

const MainMenu = () => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()

    const roles = useUserRoles()

    const hasRoles = useMemo(() => {
        return roles.includes(SSI_PROCESS_EXECUTION)
    }, [roles])

    const {
        processes,
        isProcessesLoading,
        isLocationLoading,
        processesError,
        locationsError
    } = useFetchProcesses(hasRoles)



    if (processesError?.response?.status === FORBIDDEN_STATUS || !hasRoles) {
        return <ErrorScreen />
    }


    if (isLocationLoading || isProcessesLoading) {
        return (
            <LoadingSkyCell />
        )
    }
    if (locationsError) {
        return <ErrorScreen
            title={t("ERROR_NO_LOCATION")}
        />
    }


    return (
        <div
            className={classes.container}
        >
            <h1
                className={classes.title}
            >
                {t('I_WANT_TO')}
            </h1>

            <div
                className={classes.controls}
            >
                {
                    processes?.map(({
                        processLabel,
                        processName,
                        process,
                    }) => {
                        return (
                            <NavLink
                                key={processName}
                                className={classes.control}
                                to={process}
                            >
                                {t(processLabel).toUpperCase()}
                            </NavLink>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default MainMenu
