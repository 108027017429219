import React, {
    useCallback, useContext,
} from 'react'
import {
    useHistory,
} from 'react-router-dom'
import {
    logout,
    useUserAvatar,
} from '@skycell-ag/auth'
import {
    Avatar,
} from '@skycell-ag/shared-components'
import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl'

import useStyles from './Header.style'
import AppDrawerContext from "../../context/AppDrawerContext";
import {useTranslation} from "react-i18next";
import HeaderNavigationContext from "../../context/HeaderNavigationContext";

const Header = () => {
    const classes = useStyles()

    const history = useHistory()
    const {
        setOpen:setDrawerOpen
    } = useContext(AppDrawerContext)

    const {t} = useTranslation()
    const handleClickMenu = useCallback(() => {
        // history.push('/')
        setDrawerOpen(true)
    }, [history])

    const homeIcon = getAssetBlobUrl('icons/burger.svg')

    const headerNavigationContext = useContext(HeaderNavigationContext)

    return (
        <header
            className={classes.header}
        >
            <div className={classes.btns}>
                <button
                    type="button"
                    className={classes.menu}
                    onClick={handleClickMenu}
                >
                    <img
                        className={classes.icon}
                        src={homeIcon}
                        alt="home"
                    />
                </button>
            </div>

            {
                headerNavigationContext.onSkip && <button
                    onClick={headerNavigationContext.onSkip}
                    className={classes.headerButton}
                >
                    {t("skip")}
                </button>
            }


        </header>
    )
}

export default Header
