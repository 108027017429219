import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => {
    return {
        cameraControls: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30,
            [theme.breakpoints.down("xs")]: {
                paddingTop: 10,
            },
            width: '100%',
            position: 'relative',
        },
        captureButton: {
            height: 90,
            width: 90,
            backgroundColor: theme.palette.primary[200],
            border: '2px solid #126a87',
            borderRadius: '50%',
        },
        imageContainer: {
            display: 'block',
            [theme.breakpoints.down("xs")]: {
                height: 280,
                width: 280
            }

        },
        image: {
            overflow: 'hidden',
            objectFit: 'cover',
            objectPosition: 'center',
            [theme.breakpoints.down("xs")]: {
                height: 280,
                width: 280
            }

        },
        capturer: {
            [theme.breakpoints.down("xs")]: {
                height: 280
            }
        }
    }
})

export default useStyles
