import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'

const getProcesses = ({
    queryKey: [
        _key, // eslint-disable-line no-unused-vars
        {
            location,
            token,
        },
    ],
}) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `shipmentprocess/availableprocesses?location=${location}`,
        token,
    })
        .then((data) => {
            return data.data
        })
}

export default getProcesses
