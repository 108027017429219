import axios, { Method } from 'axios'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const getConfig = (token: string) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

const getQueries = (obj: object) => {
    if (!obj) {
        return ''
    }

    const queryArr =  Object.keys(obj).reduce((queryArr, key) => {
        return [
            ...queryArr,
            `${key}=${Array.isArray(obj[key]) ? obj[key].join(',') : obj[key]}`,
        ]
    }, [])

    return `?${queryArr.join('&')}`
}


const abstractService = (endpoint = 'business-objects', version = 'v1') => {
    const instance = axios.create({
        baseURL: `${apiUrl}/${endpoint}/${version}`,
    })

    const filterFlatObject = (objectName: string, token: string) => {
        const config = getConfig(token)

        return (
            async (params: object = { page: 1 }, queryObj: object = null) => {
                try {
                    return await instance.post(
                        `${objectName.toLowerCase()}/flat/filter${getQueries(queryObj)}`,
                        params,
                        config,
                    )
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }
    const filterObject = (objectName: string, token: string) => {
        const config = getConfig(token)

        return (
            async (params: object = { page: 1 }, queryObj: object = null) => {
                try {
                    return await instance.post(
                        `${objectName.toLowerCase()}/filter${getQueries(queryObj)}`,
                        params,
                        config,
                    )
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }
    const filterRequestObject = (objectName: string, token: string) => {
        const config = {
            Accept:"application/json",
            ...getConfig(token)
        }

        return (
            async (params: object = { page: 1 }, queryObj: object = null) => {
                try {
                    return await instance.post(
                        `${objectName.toLowerCase()}/filter-request${getQueries(queryObj)}`,
                        params,
                        config,
                    )
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const getById = (objectName: string, token: string) => {
        const config = getConfig(token)

        return (
            async (id: string | number) => {
                try {
                    return await instance.get(`${objectName.toLowerCase()}/${id}`, config)
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const getFieldCount = (objectName: string, token: string) => {
        const config = getConfig(token)

        return (
            async (fieldName: string, params: object = { page: 1 }) => {
                try {
                    return await instance.post(
                        `${objectName.toLowerCase()}/filter/count/${fieldName}`,
                        params,
                        config,
                    )
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const updateObject = (objectName: string, token: string) => {
        const config = getConfig(token)

        return (
            async (data: object) => {
                try {
                    return await instance.put(objectName.toLowerCase(), data, config)
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const makeRequest = (type: Method, token: string) => {
        const config = getConfig(token)

        return (
            async (url: string, data = null) => {
                try {
                    return await instance({
                        method: type,
                        url,
                        data,
                        ...config,
                    })
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const makeExternalRequest = (type: Method) => {
        return (
            async (url: string, data = null) => {
                try {
                    return await axios({
                        method: type,
                        url,
                        data,
                    })
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    const makeMockRequest = (type: Method, url: string) => {
        return (
            async (data = null) => {
                try {
                    return await axios({
                        method: type,
                        url,
                        data,
                    })
                } catch (error) {
                    global.console.error(error)
                }
                return null
            }
        )
    }

    return {
        filterObject,
        filterRequestObject,
        filterFlatObject,
        getById,
        getFieldCount,
        makeExternalRequest,
        makeMockRequest,
        makeRequest,
        updateObject,
    }
}

export default abstractService
