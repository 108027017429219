import React, {
    useEffect, useMemo,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import Button from 'app/components/Button'

import useStyles from './YesNo.style'
import {Choice} from "../../../utils/dataTypes";
import SkipButton from "../../SkipButton";

type Props = {
    title: string,
    label: string,
    value?: any,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    choices: Choice[]
    skippable: boolean

}


const YesNo = ({
                   title,
                   label,
                   value = null,
                   onChange,
                   onSubmit,
                   choices = [],
                   skippable = false
               }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()


    const handleSubmit = useMemo<any>(() => (selected: "YES" | "NO" = "NO") => {
        onChange({
            target: {
                value: {
                    selectedChoiceIdentifier: selected
                },
            },
        }, label)
    }, [
        onChange,
        label,
    ])


    useEffect(() => {
        if (value) onSubmit()
    }, [
        onSubmit,
        value
    ])


    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>
                </div>

                <div className={classes.buttonBox}>
                    <Button
                        text={t(choices.find(it => it.choiceIdentifier === "YES").choiceLabel || 'YES')}
                        onClick={() => handleSubmit("YES")}
                    />
                    <Button
                        text={t(choices.find(it => it.choiceIdentifier === "NO").choiceLabel || 'NO')}
                        onClick={() => handleSubmit("NO")}
                    />
                </div>
                {
                    skippable && <SkipButton
                        onClick={onSubmit}
                    />
                }
            </div>
        </div>
    )
}

export default YesNo
