import React from 'react'
import {
    useHistory
} from 'react-router-dom'
import {
    useTranslation,
} from 'react-i18next'

import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl'

import useStyles from './SaveEvidence.style'
import Button from "../../Button";

const SaveEvidence = () => {
    const classes = useStyles()

    const {
        t,
    } = useTranslation()

    const history = useHistory()
    const sendShipmentImage = getAssetBlobUrl('skyship/images/sendShipment.png')

    return (
        <div
            className={classes.tabContent}
        >
            <div className={classes.container}>
                <h2
                    className={classes.title}
                >
                    {t('YOURE_GOOD_TO_GO')}
                </h2>

                <img
                    src={sendShipmentImage}
                    alt="shipment"
                />

                <Button
                    classNames={classes.btn}
                    onClick={() => history.push('/')}
                    text={t('REPEAT')}
                />
            </div>
        </div>
    )
}

export default SaveEvidence
