import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'

const getContact = ({
                         queryKey: [
                             _key, // eslint-disable-line no-unused-vars
                             {
                                 token,
                             },
                         ],
                     }) => {
    return requestBusinessObjects({
        method: 'GET',
        url: 'contact/self',
        token,
    })
        .then((data) => {
            return data.data
        })
}

export default getContact
