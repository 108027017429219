import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useMutation,
} from 'react-query'
import b64DataUrlToBlob from 'app/utils/b64DataUrlToBlob'
import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'

export const getAttachment = (token, {
    file,
    fileName,
    description,
}:any) => {
    const imgFile = new FormData()
    const blob = b64DataUrlToBlob(file, 'image/png')

    imgFile.append('file', blob)
    imgFile.append('fileName', fileName)
    if (description) {
        imgFile.append('description', description)
    }

    return requestBusinessObjects({
        method: 'POST',
        url: 'attachment',
        token,
        data: imgFile,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then((data) => {
            return data.data
        })
}

function useSaveAttachment() {
    const token = useJWTToken()
    // @ts-ignore
    return useMutation(({
        file,
        fileName,
        description,
    }:any) => {
        return getAttachment(
            token, {
                file,
                fileName,
                description,
            },
        )
    }, {
        retry: false,
        refetchOnWindowFocus: false,
    })
}

export default useSaveAttachment
