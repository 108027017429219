import React, {
    useEffect,
    useMemo,
    useState,
} from 'react'

import {
    Loading,
} from '@skycell-ag/shared-components'

import ProgressBar from 'app/components/ProgressBar'
import SavingError from 'app/components/SavingError'
import ErrorScreen from 'app/components/ErrorScreen'
import SaveEvidence from 'app/components/steps/SaveEvidence'

import AssetNumberContext from 'app/context/AssetNumberContext'

import useProcess from 'app/hooks/useProcess'
import useStyles from './Process.style'
import ProgressGroup from './ProgressGroup'
import LoadingSkyCell from "../../components/LoadingSkyCell";

const FORBIDDEN_STATUS = 403

const Process = () => {
    const classes = useStyles()

    const [
        assetNumber,
        setAssetNumber,
    ] = useState('')

    const contextValue = useMemo(() => {
        return [
            assetNumber,
            setAssetNumber,
        ]
    }, [
        assetNumber,
        setAssetNumber,
    ])


    const {
        isLoading,
        isError,
        isSuccess,
        process,
        processesError,
        tab,
        resetTab,
        groups,
        handleUpdateStepsInProgressGroup,
        saveProcess,
        onSubmit,
    } = useProcess()



    if (processesError?.response?.status === FORBIDDEN_STATUS) {
        return <ErrorScreen />
    }

    if (isLoading) {
        return (
            <LoadingSkyCell />
        )
    }

    if (isError) {
        return (
            <SavingError
                onTryAgain={saveProcess}
            />
        )
    }

    if (isSuccess) {
        return <SaveEvidence />
    }
    const withProgressBar = !["CHECK_TEMPERATURE"].includes(process?.process)

    return (
        <AssetNumberContext.Provider value={contextValue}>
            <form
                className={classes.container}
            >

                {
                    withProgressBar && <div
                        className={classes.tabProgress}
                    >
                        <ProgressBar
                            items={groups}
                            progressCount={tab}
                        />
                    </div>
                }

                {
                    groups?.map(({
                        progressGroup,
                        steps,
                    }) => {
                        return tab === progressGroup && (
                            <ProgressGroup
                                tab={tab}
                                process={process?.process}
                                key={progressGroup}
                                progressGroup={progressGroup}
                                handleToNextGroup={onSubmit}
                                handleUpdateStepsInProgressGroup={handleUpdateStepsInProgressGroup}
                                steps={steps}
                            />
                        )
                    })
                }
            </form>
        </AssetNumberContext.Provider>
    )
}

export default Process
