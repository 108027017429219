import React from 'react'

import Header from 'app/components/Header'

import styles from './Layout.module.scss'
import {ReactNodeLike} from "prop-types";
import {Drawer} from '@material-ui/core';
import DrawBarProvider from "../../DrawBarProvider";
import HeaderNavigationProvider from 'app/HeaderNavigationProvider/HeaderNavigationProvider';

type Props = {
    children: ReactNodeLike,
}


const Layout = ({
                    children = '',
                }: Props) => {
    return (
            <DrawBarProvider>
                <div className={styles.container}>

                    <Header/>

                    <div className={styles.content}>
                        {children}
                    </div>

                </div>

            </DrawBarProvider>
    )
}


export default Layout
