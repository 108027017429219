import { useJWTToken } from '@skycell-ag/auth'
import abstractService from "../../utils/abstractService";


const useMakeRequest = (type) => {
    const token = useJWTToken()
    const { makeRequest } = abstractService()

    return makeRequest(type, token)
}

export default useMakeRequest
