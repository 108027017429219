import React from 'react'
import {useEffect} from 'react';


export const DevPlug = ({
                            devFunctionName,
                            devFunction
                        }) => {
    useEffect(() => {
        document[devFunctionName] = devFunction

        return () => {
            delete document[devFunctionName]
        }
    }, [])

    return null;
}
