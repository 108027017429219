import React from 'react'
import {Loading} from "@skycell-ag/shared-components";
import {useStyles} from "./LoadingSkyCell.style";


type Props = {

}
const LoadingSkyCell = ({}: Props) => {


    const classes = useStyles()

    return <Loading className={classes.loading}/>
}

export default LoadingSkyCell
