import React, {useCallback} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import Button from 'app/components/Button'

import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl'

import useStyles from './SavingError.style'

import {
    useHistory,
} from 'react-router-dom'

type Props = {
    onTryAgain?: (...arg: any) => any,
    title?: string
}


const SavingError = ({
    onTryAgain = () => {},
    title = 'CANT_SAVE_DATA'
}: Props) => {
    const {
        t,
    } = useTranslation()
    const classes = useStyles()

    const history = useHistory()

    const ErrorImage = getAssetBlobUrl('skyship/images/error_backend_not_available.svg')

    const onCancel = useCallback(() => {
        history.push('/')
    }, [history])

    return (
        <div
            className={classes.container}
        >
            <img
                className={classes.img}
                src={ErrorImage}
                alt="shipment"
            />

            <h2
                className={classes.title}
            >
                {t(title)}
            </h2>

            <div className={classes.divider}/>
            <div className={classes.buttonsContainer}>
                <Button
                    text={t('CANCEL')}
                    onClick={onCancel}
                />
                <Button
                    text={t('TRY_AGAIN')}
                    onClick={onTryAgain}
                />

            </div>
        </div>
    )
}

export default SavingError
