import React from 'react'
import {useTranslation} from "react-i18next";
import CheckIcon from "@material-ui/icons/Check";
import Button from "../../../Button";
import useStyles from "../../Confirmation/Confirmation.style";


type Props = {
    title?: string,
    onProceed: any
}
const ConditionScreen = ({
                             title = "PICTURES_COMPARED",
                             onProceed
                         }: Props) => {
    const {t} = useTranslation()

    const classes = useStyles()

    return <div
        className={classes.tabContent}
    >
        <div
            className={classes.container}
        >
            <div
                className={classes.content}
            >
                <h2
                    className={classes.title}
                >
                    {t(title)}
                </h2>

            </div>

            <Button
                text={t("NEXT")}
                onClick={onProceed}
            />
        </div>
    </div>
}

export default ConditionScreen
