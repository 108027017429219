import React, {useCallback, useEffect, useState} from 'react'
import useStyles from "../TemperatureInfo.style";
import {Card} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Container, SelectedAsset, TempRangeStatus} from "../../../../utils/dataTypes";
import {ICONS} from "../../../../utils/constants";
import getGlobalAssetUrl from "../../../../utils/getGlobalAssetUrl";
import moment from "moment";
import {Tooltip} from '@mui/material';


type Props = {
    serialNumber: string,
    shipmentNumber: string,
    selectedAsset: SelectedAsset,
    container: Container,
    fromDate: moment.Moment,
    toDate: moment.Moment,
    fromTooltip: string,
    toTooltip: string,
}


const TempRangeStatusIcon = (string: TempRangeStatus) => getGlobalAssetUrl(({
    [TempRangeStatus.EXCURSION]: ICONS.cross,
    [TempRangeStatus.IN_RANGE]: ICONS.checked_blue,
    [TempRangeStatus.NOT_APPLICABLE]: ICONS.dots_round_grey,
    [TempRangeStatus.UNKNOWN]: ICONS.dots_round_grey,
    [TempRangeStatus.WAITING_FOR_DATA]: ICONS.dots_round_grey,
})[string] || ICONS.dots_round_grey)
const shownDate = (date) => date?.split(" ")?.[0] || "—"

const ShipmentCard = ({
                          serialNumber = '',
                          shipmentNumber = '',
                          selectedAsset,
                          container,
                          fromDate,
                          toDate,
                          fromTooltip,
                          toTooltip,
                      }: Props) => {
    const classes = useStyles()
    const {t} = useTranslation()

    const [tooltipState, setTooltipState] = useState({
        from: false,
        to: false
    })

    useEffect(() => {
        const handler = () => {
            setTooltipState({from: false, to: false})
        }
        document.addEventListener('click', handler)
        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    const handleTooltipClick = (ev: React.MouseEvent<HTMLSpanElement>, type: "from" | "to") => {
        ev.stopPropagation();
        setTooltipState(() => ({
            from: type === 'from',
            to: type === 'to'
        }))
    }

    const minMax = useCallback((min, max) => {
        return <div className={classes.minMaxRange}>
            <span>{`${t("MIN")}: ${(min || '-') + "°C"}`}</span>
            <span>{`${t("MAX")}: ${(max || '-') + '°C'}`}</span>
        </div>
    }, [])

    return <>
        <div className={classes.shimpentCardHolder}>
            <Card>
                <div className={classes.shipmentCardContainer}>
                    <div className={classes.shipmentCardTitle}>
                        <span><b>{t('CONTAINER')}:</b>{serialNumber}</span>
                        <span><b>{t('TEMPERATURE.SHIPMENT')}:</b>{shipmentNumber}</span>
                    </div>
                    <div className={classes.shipmentCardBody}>
                        <>
                            <span>{t('TEMPERATURE.ALLOWED_RANGE')}</span>
                            <span>{minMax(selectedAsset?.productRelease?.temperatureRangeMin,
                                selectedAsset?.productRelease?.temperatureRangeMax)}</span>
                        </>
                        <>
                            <span>{t('TEMPERATURE.ACTUAL_RANGE')}</span>
                            <span>{minMax(selectedAsset?.productRelease?.temperatureMin,
                                selectedAsset?.productRelease?.temperatureMax)}</span>
                        </>
                        <>
                            <span>{t('TEMPERATURE.RANGE_CHECK')}</span>
                            <span>
                                <img
                                    className={classes.icon}
                                    src={TempRangeStatusIcon(selectedAsset?.productRelease?.temperatureStatus)}
                                    alt="tempCheck"
                                />
                                {
                                    t(selectedAsset?.productRelease?.temperatureStatus)
                                }
                            </span>
                        </>
                        <>
                            <span>{t('TEMPERATURE.PERIOD')}</span>
                            <span>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                        modifiers: [
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [0, -6],
                                                },
                                            },
                                        ],
                                    }}
                                    onClose={() => setTooltipState(prev => ({...prev, from: false}))}
                                    open={tooltipState.from}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    arrow={true}
                                    title={fromTooltip}
                                >
                                    <span
                                        className={classes.spanTooltip}
                                        onClick={(event) => handleTooltipClick(event, 'from')}
                                    >{(fromDate && fromDate.format('DD.MM.YYYY')) || ''}</span>
                                </Tooltip>
                                <span>{` ${t('TO')} `}</span>
                                 <Tooltip
                                     PopperProps={{
                                         disablePortal: true,
                                         modifiers: [
                                             {
                                                 name: "offset",
                                                 options: {
                                                     offset: [0, -6],
                                                 },
                                             },
                                         ],
                                     }}
                                     onClose={() => setTooltipState(prev => ({...prev, to: false}))}
                                     open={tooltipState.to}
                                     arrow={true}
                                     disableFocusListener
                                     disableHoverListener
                                     disableTouchListener
                                     title={toTooltip}
                                 ><span
                                     className={classes.spanTooltip}
                                     onClick={(event) => handleTooltipClick(event, 'to')}
                                 >
                                     {(toDate && toDate.format('DD.MM.YYYY')) || ''}</span>
                                 </Tooltip>
                            </span>
                        </>
                    </div>
                </div>
            </Card>
        </div>
    </>
}

export default ShipmentCard
