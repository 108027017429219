import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useQuery,
} from 'react-query'

import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'


const getOrderFromSelectedAsset = ({
    queryKey: [
        // eslint-disable-next-line no-unused-vars
        _key,
        {
            token,
            assetNumber,
            assetType,
            process,
            locationId,
        },
    ],
}) => {
    return requestBusinessObjects({
        method: 'GET',
        url: `orderfromselectedasset?assetNumber=${assetNumber}&locationId=${locationId}&assetType=${assetType}&process=${process}`,
        token,
    }).then((response) => {
        return response.data
    })
}

function useOrderFromSelectedAsset({
    assetNumber,
    locationId,
    ...rest
}) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'findMatchedEvidence',
            {
                token,
                assetNumber,
                locationId,
                ...rest,
            },
        ],
        queryFn: getOrderFromSelectedAsset,
        enabled: Boolean(assetNumber && locationId),
        onError: (error)=> {console.log("TError", error)},
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })
}

export default useOrderFromSelectedAsset
