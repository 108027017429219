import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => {
    return {
        progress: {
            position: 'relative',
            height: 40,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'stretch',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        progressCircle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 30,
            height: 30,
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[300],
            zIndex: 2,
        },
        progressCircleCheck: {
            backgroundColor: theme.palette.primary[200],
            width: 30,
            height: 30,
        },
        progressCircleCurrent: {
            backgroundColor: theme.palette.primary[200],
            width: 50,
            height: 50,
        },
        progressCheck: {
            color: theme.palette.common.white,
            width: 30,
            height: 30,
        },
        baseline: {
            width: '100%',
            height: 1,
            backgroundColor: theme.palette.primary[200],
            position: 'absolute',
            top: 20,
            zIndex: 0,
        },
    }
})

export default useStyles
