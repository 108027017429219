export const dateTimeISO = 'DD.MM.YYYY HH:mm ZZ'
export const dateTimeMask = 'DD.MM.YYYY THH:mm ZZ'
export const dateTimeMaskTimestamp = 'DD.MM.YYYY THH:mm:ss ZZ'
export const dateTimeMaskTimestampUTC = 'DD.MM.YYYY THH:mm:ss'


export const TemperatureIcons = {
    C: `icons/temperature_C.svg`,
    CRT: `icons/temperature_CRT.svg`,
    DF: `icons/temperature_DF.svg`,
    F: `icons/temperature_F.svg`,
}

export const ICONS = {
    checked_blue: 'icons/checked_blue.svg',
    dots_round_grey: 'icons/dots_round_grey.svg',
    cross: 'icons/cross.svg'
}
export const IMAGES = {
    sleeping: 'skyship/images/sleeping.svg',
}

export const TEMPERATURE_RANGES = {
    CRT: 'CRT (15 °C - 25 °C)',
    C: 'C (2°C - 8°C)',
    F: 'F (-25°C - 15°C)',
    DF: 'DF (-80°C - 60°C)',
}

export enum TEMP_RANGE_CHECK {
    OK = 'OK',
    NO_DATA = "NO_DATA",
    FAILED_TEMPERATURE= "FAILED_TEMPERATURE"
}



