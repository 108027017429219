import React from 'react'
import cx from 'classnames'
import {
    useTranslation,
} from 'react-i18next'

import useStyles from './FieldTitle.style'

type Props = {
    title: string,
    subTitle?: string,
}

const FieldTitle = ({
    title,
    subTitle = '',
}: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()

    return (
        <>
            <div className={classes.tabTitles}>
                <h2
                    className={cx(
                        classes.title,
                        classes.mainTitle,
                    )}
                >
                    { t(title) }
                </h2>

                <h3
                    className={cx(
                        classes.title,
                        classes.subTitle,
                    )}
                >
                    { t(subTitle) }
                </h3>
            </div>
        </>
    )
}


export default FieldTitle
