import React, {
    useEffect, useState,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import FieldTitle from 'app/components/FieldTitle'
import BarcodeDecodedMessage from 'app/components/BarcodeReader/BarcodeDecodedMessage'
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage'

import useOrderFromSelectedAsset from 'app/hooks/useOrderFromSelectedAsset'
import useAssetNumber from 'app/hooks/useAssetNumber'
import {useHistory} from 'react-router-dom'
import {
    // @ts-ignore
    useUserLocations,
} from '@skycell-ag/shared-components'
import styles from './PalletNumberComparison.module.scss'
import LoadingSkyCell from "../../LoadingSkyCell";
import MultiReader from "../../MultiReader";
import {Input} from '@material-ui/core'
import Button from "../../Button";

type Props = {
    title?: string,
    process: string,
    value?: {
        palletCode: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    onReturn: (...arg: any) => any,
}


const PalletNumberComparison = ({
                                    value = null,
                                    title = 'PALLET_CODE_COMPARISON',
                                    subTitle = '',
                                    label,
                                    process,
                                    skippable = false,
                                    onChange,
                                    onSubmit,
                                    onReturn,
                                }: Props) => {
    const {
        t,
    } = useTranslation()

    const [manualInput, setManualInput] = useState('')
    const [showManualInput, setShowManualInput] = useState(false)
    const [
        assetNumber,
        setAssetNumber,
    ] = useAssetNumber()

    const history = useHistory()
    const {
        data: locations,
        isLoading: isLocationsLoading,
        error: locationsError,
    } = useUserLocations({
        refetchOnMount: false,
    })

    const {
        data,
        error,
        isLoading,
    } = useOrderFromSelectedAsset({
        assetNumber: value?.palletCode,
        assetType: 'PALLET',
        locationId: locations?.[0]?.id,
        process: process
    })

    useEffect(() => {
        if (!assetNumber && value?.palletCode) {
            setAssetNumber(value?.palletCode)
        }
    }, [
        value,
        assetNumber,
        setAssetNumber,
    ])

    if (isLoading || isLocationsLoading) {
        return <LoadingSkyCell/>
    }

    if (value?.palletCode && data) {
        return (
            <BarcodeDecodedMessage
                value={value?.palletCode}
                onSubmit={onSubmit}
                identifiedMessage="PALLET_IDENTIFIED"
                onRepeat={
                    () => onChange({
                        target: {
                            value: "",
                        },
                    }, label)
                }
            />
        )
    }

    if (error || locationsError) {
        return (
            <ContainerValidationErrorMessage
                serialNumber={value?.palletCode}
                error={{
                    errorCode: 2,
                    message: "NO_VALID_ORDER_FOR_PALLET"
                }}
                title={"PALLET"}
                onRepeat={() => {
                    onChange({
                        target: {
                            value: "",
                        },
                    }, label)
                    setManualInput("")

                }}
            />
        )
    }

    return (
        <>
            <FieldTitle
                title={t(showManualInput?'ENTER_PALLET_CODE':title)}
                subTitle={subTitle}
            />

            <div
                className={styles.tabContent}
            >
                {
                    !showManualInput && <>

                        <MultiReader
                            label={label}
                            skippable={skippable}
                            rawInput={true}
                            onScan={(value) => {
                                onChange({
                                    target: {
                                        value: value,
                                    },
                                }, label)

                            }}
                            onSkip={onSubmit}
                        />

                        <div className={styles.buttonsWrapper}>
                            <Button
                                text={t("ENTER_MANUALLY")}
                                onClick={() => {
                                    setShowManualInput(true)
                                }}
                            />
                            <Button
                                text={t('CANCEL')}
                                onClick={() => {
                                    history.push('/')
                                }}
                            />
                        </div>
                    </>
                }


                {
                    showManualInput && <div className={styles.manualInput}>
                        <Input
                            className={styles.input}
                            placeholder={t("ENTER_CODE")}
                            value={manualInput}
                            onChange={e => setManualInput(e.target.value)}
                        />
                        <div className={styles.divider}/>
                        <div className={styles.buttonsWrapper}>
                            <Button
                                text={t('CANCEL')}
                                onClick={() => {
                                    setShowManualInput(false)
                                    setManualInput("")

                                }}
                            />
                            <Button
                                text={t("CONFIRM")}
                                disabled={!manualInput}
                                onClick={() => {
                                    setShowManualInput(false)
                                    onChange({
                                        target: {
                                            value: manualInput,
                                        },
                                    }, label)
                                }}
                            />
                        </div>


                    </div>
                }
            </div>
        </>
    )
}

export default PalletNumberComparison
