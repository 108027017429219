import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import CheckIcon from '@material-ui/icons/Check'
import {
    useTranslation,
} from 'react-i18next'
import {
    dateToStr,
} from '@skycell-ag/shared-components'

import Button from 'app/components/Button'

import useStyles from './ChoiceMultiple.style'
import {Choice} from "../../../utils/dataTypes";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import SkipButton from "../../SkipButton";

type Props = {
    title: string,
    label: string,
    value?: any,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    choices: Choice[]
    skippable: boolean

}


const ChoiceMultiple = ({
                            title,
                            label,
                            value = null,
                            onChange,
                            onSubmit,
                            choices = [],
                            skippable = false

                        }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()
    const [values, setValues] = useState([])


    const handleSubmit = useCallback(() => {
        onChange({
            target: {
                value: {
                    selectedChoiceIdentifiers: values
                },
            },
        }, label)
    }, [
        onChange,
        values,
        label,
    ])

    useEffect(() => {
        if (value) onSubmit()
    }, [
        onSubmit,
        value
    ])

    const valueChanged = (identifier, value) => {
        setValues(prevState => {
            if (value) return [
                ...prevState, identifier
            ]
            else return prevState.filter(it => it !== identifier)
        })
    }


    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>
                </div>

                <div className={classes.choices}>
                    {
                        choices.map(choice => {
                            return <FormControlLabel
                                key={`CHOICE_${choice.choiceIdentifier}`}
                                className={classes.choice}
                                checked={values.includes(choice.choiceIdentifier)}
                                onChange={(event, value) => {
                                    valueChanged(choice.choiceIdentifier, value)
                                }}
                                label={
                                    <span className={classes.checkboxLabel}>{t(choice.choiceLabel)}</span>
                                }
                                control={
                                    <Checkbox
                                        className={classes.checkbox}
                                    />
                                }
                            />
                        })
                    }
                </div>

                <Button
                    text={t('NEXT')}
                    onClick={handleSubmit}
                />
                {
                    skippable && <SkipButton
                        onClick={onSubmit}
                    />
                }

            </div>
        </div>
    )
}

export default ChoiceMultiple
