import React from 'react'
import {
    BrowserRouter,
} from 'react-router-dom'
import Auth from '@skycell-ag/auth'
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query'
import {
    I18nLocaleProvider,
} from '@skycell-ag/shared-components'
import {
    initVariables,
} from '@skycell-ag/shared-components/dist/init'

import Root from 'app/Root'
import StylesProvider from './StylesProvider'

import './i18n.ts'
import './App.scss'
import HeaderNavigationProvider from './HeaderNavigationProvider'

initVariables({
    REACT_APP_SKYMIND_API: process.env.REACT_APP_SKYMIND_API,
})

const queryClient = new QueryClient()

const App = () => {
    return (
        <BrowserRouter>
            <Auth>
                <StylesProvider>
                    <QueryClientProvider client={queryClient}>
                        <I18nLocaleProvider>
                            <HeaderNavigationProvider>
                                <Root/>
                            </HeaderNavigationProvider>

                        </I18nLocaleProvider>
                    </QueryClientProvider>
                </StylesProvider>
            </Auth>
        </BrowserRouter>
    )
}

export default App
