import moment from "moment";


type SensorDataDetails = {
    sensorData?: any[],
    leaseStartTimestamp?: string,
    leaseEndTimestamp?: string,
    baseLeaseUntil?: string,
    from?: string,
    to?: string,
}
export const trimSensorData = ({
                                   sensorData,
                                   leaseStartTimestamp,
                                   leaseEndTimestamp,
                                   from,
                                   to,
                                   baseLeaseUntil
                               }: SensorDataDetails) => {
    if (!sensorData || sensorData.length === 0) {
        return []
    }
    const itemStep = 10 * 60 * 1000
    const nullObj = sensorData?.[0]?.d.map(it => null) || []
    const sensorDataFirstDate = new Date(sensorData?.[0]?.t)
    const sensorDataLastDate = new Date(sensorData[sensorData.length - 1].t)
    const startArr = []
    const finishArr = []

    let globalToMillis;
    let globalFromMillis;

    if (from) {
        globalFromMillis = moment(from, 'YYYY-MM-DDTHH:mm')
            .utc(false).valueOf()
    } else {
        globalFromMillis = moment(leaseStartTimestamp, 'DD-MM-YYYY HH:mm Z')
            .utc(false).valueOf()
    }

    if (to) {
        globalToMillis = moment(to, "YYYY-MM-DDTHH:mm").utc(false).valueOf()
    }  else if (leaseEndTimestamp) {
        globalToMillis = moment(leaseEndTimestamp, "DD-MM-YYYY HH:mm Z").utc(false).valueOf()
    } else {
        globalToMillis = moment(baseLeaseUntil, "YYYY-MM-DDTHH:mm").utc(false).valueOf()
    }

    if (globalFromMillis + itemStep < sensorDataFirstDate.getTime()) {
        startArr.push({
            t: new Date(globalFromMillis - 60*60*1000).toISOString(),
            d: nullObj
        })
    }

    if (sensorDataLastDate.getTime() + itemStep < globalToMillis) {
        finishArr.push({
            t: new Date(globalToMillis).toISOString(),
            d: nullObj
        })
    }

    return [
        ...startArr,
        ...sensorData,
        ...finishArr,
    ]
}

export const momentAddOffset = (date: moment.Moment) => {
    return date.subtract({minute: new Date().getTimezoneOffset()})
}
