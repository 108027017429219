import React, {useEffect, useMemo, useState} from 'react'

import useStyles from './../TemperatureInfo.style'
import {useTranslation} from 'react-i18next';
import ShipmentCard from "./../ShipmentCard";
import MeasurementCard from "./../MeasurementCard";
import {
    useJWTToken,
} from '@skycell-ag/auth'
//@ts-ignore
import {dateToStr, requestBusinessObjects, useUserLocations} from "@skycell-ag/shared-components";
import sensorDataService from "../../../../utils/sensorDataService";
import {IMAGES, TEMP_RANGE_CHECK} from "../../../../utils/constants";
import {Container, Order, SelectedAsset} from "../../../../utils/dataTypes";
import TemperatureChart from "../../../TemperatureChart";
import getGlobalAssetUrl from "../../../../utils/getGlobalAssetUrl";
import {trimSensorData} from "../../../../utils/tools";
import useFilterObject from 'app/hooks/useFilterObject';

type Props = {
    order: Order,
    serialNumber: string,
    buttonPressed: string,
    setSensorDataLoading: any,
    fromDate: any,
    toDate: any,
    expectedLease: any
}


const TemperatureInfoContents = ({
                                     serialNumber,
                                     order,
                                     buttonPressed,
                                     setSensorDataLoading,
                                     fromDate,
                                     toDate,
                                     expectedLease
                                 }: Props) => {
    const token = useJWTToken()
    const classes = useStyles()
    const {t} = useTranslation()
    const [tempCheckResult, setTempCheck] = useState<TEMP_RANGE_CHECK | null>(null) // No temp check service yet
    const [container, setContainer] = useState<Container>(null)
    const [sensorData, setSensorData] = useState([])
    const {getTemperatureInRange} = sensorDataService
    const getContainers = useFilterObject('container')

    useEffect(() => {
        if (serialNumber) {
            (async () => {
                try {
                    const containersResponse = await getContainers({
                            includeFilters: {serialNumber: [serialNumber]}
                        }
                    )
                    const tempCheckResponse = await getTemperatureInRange(token, serialNumber)
                    if (tempCheckResponse?.data?.checkResult) {
                        setTempCheck(tempCheckResponse.data.checkResult)
                    }
                    if (containersResponse?.data?.items) {
                        const [rawContainer = null] = containersResponse.data.items
                        setContainer(rawContainer)
                    }
                } catch (error) {
                    global.console.log(error)
                    setContainer(null)
                }
            })()


        } else {
            setContainer(null)
        }

    }, [serialNumber])

    const defaultInternalLogger = useMemo(() => {
        return container?.loggerContainerInformation
            ?.find(logger =>
                logger?.loggerPosition === 'INTERNAL'
                && logger.isCurrentLogger
                && logger.logger.loggerType === container.defaultLoggerType)
    }, [container])


    const selectedAsset = useMemo<SelectedAsset>(
        () => order?.selectedContainers?.find(it => it?.container?.serialNumber === serialNumber),
        [order])


    console.log({baseLeaseUntil: order?.billing?.baseLeaseUntil})
    useEffect(() => {

        if (buttonPressed === 'NOW') return;

        const loggers = [
            {loggerType: defaultInternalLogger?.logger?.loggerType},
            {loggerType: "SKYCELL_SAVY_BLE"}];

        (async () => {
                setSensorDataLoading(true)
                const result = await sensorDataService.getSensorData(
                    {
                        token,
                        serialNumber,
                        loggers,
                        from: fromDate,
                        to: toDate,
                        door: true,
                    })

                setSensorData(result?.data?.data || [])
                setSensorDataLoading(false)
            }
        )()

    }, [
        buttonPressed,
        defaultInternalLogger,
        order,
        fromDate,
        toDate,
        setSensorDataLoading
    ])


    const updatedSensorData = useMemo(() => {
        let isShipmentOption = buttonPressed === 'shipment';

        return trimSensorData({
            sensorData: sensorData,
            leaseStartTimestamp: order?.billing?.leaseStartTimestamp,
            leaseEndTimestamp: order?.billing?.leaseEndTimestamp,
            baseLeaseUntil: order?.billing?.baseLeaseUntil,
            from: isShipmentOption ? null : fromDate,
            to: isShipmentOption ? null : toDate,
        })
    }, [
        sensorData,
        buttonPressed,
        selectedAsset?.productRelease?.containerOpenedOn,
        order?.billing?.leaseStartTimestamp,
        order?.billing?.leaseEndTimestamp,
        order?.billing?.baseLeaseUntil,
        fromDate,
        toDate,
    ],)

    console.log({
        leaseStart: order?.billing?.leaseStartTimestamp
    })
    return (
        <>
            {
                buttonPressed !== 'SHIPMENT' ? <div className={classes.topScreen}>
                    <span className={classes.containerText}>{t('CONTAINER')}</span>
                    <span className={classes.serialNumber}>{serialNumber}</span>
                </div> : <ShipmentCard
                    serialNumber={serialNumber}
                    shipmentNumber={order?.orderNumber}
                    selectedAsset={selectedAsset}
                    container={container}
                    fromDate={fromDate}
                    toDate={toDate}
                    fromTooltip={
                        (order.shipmentStart && "Shipment Start Date")
                        || (order?.billing?.leaseStartTimestamp && "Lease Start Date")
                        || 'No Date'
                    }
                    toTooltip={
                        (order.shipmentEnd && "Shipment End Date")
                        || (order?.billing?.leaseEndTimestamp && "Lease End Date")
                        || "Expected Lease End Date"
                    }

                />
            }

            {
                buttonPressed === 'NOW' ?
                    <MeasurementCard
                        tempRangeCheck={tempCheckResult || undefined}
                        temperatureRange={container?.tempRange}
                        lastMeasuredTempInternal={defaultInternalLogger?.logger?.lastMeasuredTemp}
                        lastMeasuredOnUtcInternal={container?.lastMeasuredOnUtcInternal}
                    /> : <></>
            }

            {
                ["24h", "7d", "14d", "SHIPMENT"].includes(buttonPressed) &&
                (sensorData?.length === 0 ?
                    <img
                        className={classes.sleepingImg}
                        src={getGlobalAssetUrl(IMAGES.sleeping)}
                        alt="sleep"
                    /> :
                    <TemperatureChart
                        data={updatedSensorData}
                        containerType={container?.tempRange}
                        chartMounted={() => setSensorDataLoading(false)}
                        excursionOn={selectedAsset?.productRelease?.temperatureExcursionOn}
                        leaseEndTimestamp={!order.shipmentStart && order?.billing?.leaseEndTimestamp}
                        leaseStartTimestamp={!order.shipmentEnd && order?.billing?.leaseStartTimestamp}
                        shipmentStart={order.shipmentStart}
                        shipmentEnd={order.shipmentEnd}
                        expectedLease={buttonPressed === 'SHIPMENT' ? expectedLease?.format('DD.MM.YYYY HH:mm') : null}
                    />)
            }


        </>
    )
}

export default TemperatureInfoContents
