import {
    useState,
    useCallback,
    useEffect,
    useMemo,
} from 'react'
import {
    useParams,
} from 'react-router-dom'
import {
    dateToStr,
} from '@skycell-ag/shared-components'

import useCreateProcessEvidence from 'app/hooks/useCreateProcessEvidence'
import useFetchProcesses from 'app/hooks/useFetchProcesses'

import stepsMapper from 'app/components/steps/stepsMapper'
import useTabs from 'app/hooks/useTabs'
import {dateTimeISO, dateTimeMask} from "../../utils/constants";

const useProcess = () => {
    const {
        process: processNameFromParams,
    } = useParams()



    const {
        processes,
        location,
        isLocationLoading,
        isProcessesLoading,
        processesError,
        locationsError
    } = useFetchProcesses()

    const [
        isFullfiled,
        setFullfiled,
    ] = useState(false)

    const process = useMemo(() => {
        return processes?.find((item) => {
            return item.process === processNameFromParams
        })
    }, [
        processes,
        processNameFromParams,
    ])

    const [
        tab,
        handleToNextTab,
        resetTab
    ] = useTabs(1)

    const [
        progressGroupsWithInputs,
        setProgressGroupsWithInputs,
    ] = useState(process?.progressGroups)

    useEffect(() => {
        if(!progressGroupsWithInputs) setProgressGroupsWithInputs(process?.progressGroups)
    }, [process])

    const handleUpdateStepsInProgressGroup = useCallback((progressGroup, steps) => {
        const updatedProgressGroups = progressGroupsWithInputs?.map((item) => {
            if (item.progressGroup === progressGroup) {
                return {
                    progressGroup,
                    steps,
                }
            }

            return item
        })

        setProgressGroupsWithInputs(updatedProgressGroups)
    }, [
        progressGroupsWithInputs,
        setProgressGroupsWithInputs,
    ])

    const {
        mutate,
        isError,
        isLoading: isSavingLoading,
        isSuccess,
    } = useCreateProcessEvidence()

    const groups = useMemo(() => {
        return process?.progressGroups.reduce((acc, group) => {
            const isStepUnmatched = group?.steps?.find((step) => {
                return !stepsMapper[step.stepType]
            })

            if (isStepUnmatched) {
                return acc
            }

            return [
                ...acc,
                group,
            ]
        }, [])
    }, [process])

    const isLoading = useMemo(() => {
        return isLocationLoading || isProcessesLoading || isSavingLoading
    }, [
        isLocationLoading,
        isProcessesLoading,
        isSavingLoading,
    ])

    const onSubmit = useCallback(() => {
        if (groups.length === tab) {
            setFullfiled(true)
        } else {
            handleToNextTab()
        }
    }, [
        groups,
        tab,
        handleToNextTab,
    ])

    const saveProcess = useCallback(() => {
        if (!process) return

        console.log({progressGroupsWithInputs})
        console.log({process,})
        const {
            id: _id, ...rest
        } = process
        const mutationVariables:any = {
            ...rest,
            //@ts-ignore
            createdOnZoned: dateToStr(new Date(), dateTimeISO),
            //@ts-ignore
            changedOnZoned: dateToStr(new Date(), dateTimeISO),
            location: {
                id: location?.id,
            },
            progressGroups: progressGroupsWithInputs,
        }

        mutate(mutationVariables)
    }, [
        process,
        progressGroupsWithInputs,
        location,
        mutate,
    ])

    useEffect(() => {
        if (isFullfiled && !isSavingLoading) {
            saveProcess()
        }
    }, [
        isFullfiled,
        saveProcess,
    ])

    return {
        isLoading,
        isError,
        isSuccess,
        process,
        processesError,
        locationsError,
        groups,
        tab,
        resetTab,
        handleUpdateStepsInProgressGroup,
        onSubmit,
        saveProcess,
    }
}

export default useProcess
