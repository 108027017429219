import {useLayoutEffect, useState} from 'react'

interface ScreenSize {
    width: number,
    height: number
}



const useScreenSize = (): ScreenSize => {
    const [screenSizeState, setScreenSizeState] = useState<ScreenSize>({
        width: window.innerWidth,
        height: window.innerHeight,
    })



    useLayoutEffect(() => {
        const update = () => {
            setScreenSizeState({width: window.innerWidth, height: window.innerHeight});
        }
        window.addEventListener('resize', update);
        update();
        return () => window.removeEventListener('resize', update);
    }, []);


    return {...screenSizeState};
}
export default useScreenSize;