import getContact from './getContact'
import useQueryGetUserContacts from './useQueryGetUserContacts'

const getLocation = async (args:any) => {
    const contact = await getContact(args)
    return contact?.location || {
        id: 3
    }
}

const useUserLocation = (options) => {
    return useQueryGetUserContacts(getLocation, options)
}

export default useUserLocation
