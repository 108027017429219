import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import {
    useTranslation,
} from 'react-i18next'

import useStyles from './AcceptButton.style'

type Props = {
    onCancel?: (arg?:any)=>any,
    onSubmit?: (arg?:any)=>any,
    submitBtnText?: string,
    cancelBtnText?: string,
}


const AcceptButton = ({
    onCancel = () => {},
    onSubmit = () => {},
    submitBtnText = '',
    cancelBtnText = '',
}: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()

    return (
        <div
            className={classes.tabControl}
        >
            <button
                type="button"
                name="reject"
                className={classes.btn}
                onClick={onCancel}
            >
                <Close
                    className={classes.icon}
                />
                <span>
                    { cancelBtnText || t('REJECT') }
                </span>
            </button>

            <button
                type="button"
                name="accept"
                className={classes.btn}
                onClick={onSubmit}
            >
                <CheckIcon
                    className={classes.icon}
                />
                <span>
                    { submitBtnText || t('ACCEPT') }
                </span>
            </button>
        </div>
    )
}

export default AcceptButton
