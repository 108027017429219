import AppDrawerContext from 'app/context/AppDrawerContext'
import React, {useEffect, useMemo, useState} from 'react'
import {ReactNodeLike} from "prop-types";
import {Drawer, SwipeableDrawer} from "@material-ui/core";
import AppDrawerMenuItem from "../components/AppDrawerMenuItem";
import useStyles from "../components/Header/Header.style";
import {AppMenuItem, AppMenuItemType, Process} from "../utils/dataTypes";
import {useTranslation} from "react-i18next";
import {
    logout,
} from '@skycell-ag/auth'
import getGlobalAssetUrl from "../utils/getGlobalAssetUrl";
// @ts-ignore
import {useUserLocations} from "@skycell-ag/shared-components";
import { useAuth } from '@skycell-ag/auth'
import useFetchProcesses from "../hooks/useFetchProcesses";
import {SSI_PROCESS_EXECUTION} from "../screens/MainMenu/MainMenu";
import {
    useUserRoles,
} from '@skycell-ag/auth'


type Props = {
    children: ReactNodeLike
}

const processIconMap = (process) => ({
    CHECK_TEMPERATURE:'icons/magnify.svg',
    RECEIVING:'icons/checkboard.svg',
    SENDING:'icons/truck_loading.svg'
})[process]

const convertProcessToMenuItem:any = (process:Process,t) => {

    return {
        label:t(`${process.processLabel}`),
        type:AppMenuItemType.ROUTE,
        route: `/${process.process}`,
        icon: processIconMap(process.process)
    }
}

const DrawBarProvider = ({
                             children = []
                         }: Props) => {

    const [open, setOpen] = useState(false)
    const {t, i18n} = useTranslation()
    const classes = useStyles()

    const roles = useUserRoles()

    const hasRoles = useMemo(() => {
        return roles.includes(SSI_PROCESS_EXECUTION)
    }, [roles])

    const {
        processes,
        location,
        processesError,
        locationsError,
    } = useFetchProcesses(hasRoles)

    const { user } = useAuth()


    const homeMenuItem = useMemo<AppMenuItem>(() => ({
        label: t("HOME"),
        type: AppMenuItemType.ROUTE,
        route: '/',
        icon: 'icons/home.svg'
    }), [i18n.language])


    const processItems =  useMemo<AppMenuItem>(() => ({
        label: t("PROCESSES"),
        type: AppMenuItemType.PARENT,
        children: processes?.map(process => convertProcessToMenuItem(process, t)) || []
    }), [i18n.language, processes])

    const userMenuItems = useMemo<AppMenuItem[]>(() => [
        {
            label: t("ACCOUNT_SETTINGS"),
            type: AppMenuItemType.PARENT,
            children: [
                {
                    label:  `${user?.firstName} ${user?.lastName}`,
                    type: AppMenuItemType.INFO,
                    icon: 'icons/person_white.svg'
                },
                {
                    label: locationsError?t("NO_COMPANY"):location?.company?.companyName,
                    type: AppMenuItemType.INFO,
                    icon: 'icons/warehouse_white.svg'
                },
                {
                    label: t("LOGOUT"),
                    onClick: logout,
                    type: AppMenuItemType.ROUTE,
                    icon: 'icons/logout.svg'
                }
            ]
        }
    ], [i18n.language, location, user])


    return <AppDrawerContext.Provider
        value={{
            open,
            setOpen,
        }}>

        <SwipeableDrawer
                anchor={'left'}
                classes={{paper: classes.paper}}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <div className={classes.closer}>
                    <img
                        className={classes.x}
                        src={getGlobalAssetUrl('icons/closer.svg')}
                        alt={'x'}
                        onClick={() => setOpen(false)}
                    />
                </div>
                <div className={classes.drawer}>
                    {[homeMenuItem, processItems, ...userMenuItems].map(menuItem =>
                        <AppDrawerMenuItem key={menuItem.label} {...menuItem} onClose={() => setOpen(false)}/>)}
                </div>
            </SwipeableDrawer>


        {children}
    </AppDrawerContext.Provider>


}
export default DrawBarProvider
