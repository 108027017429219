import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import Button from 'app/components/Button'

import useStyles from './TextValidation.style'
import {TextField} from "@material-ui/core";
import SkipButton from "../../SkipButton";

type Props = {
    title: string,
    label: string,
    value?: any,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    helpText: string
    validationRegEx: string
    validationErrorMessage: string
    skippable: boolean

}


const TextValidation = ({
                            title,
                            label,
                            value = null,
                            onChange,
                            onSubmit,
                            helpText,
                            validationRegEx,
                            validationErrorMessage,
                            skippable = false
                        }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()
    const [answerText, setAnswerText] = useState("")

    const [validation, setValidation] = useState(null)

    useEffect(() => {
        const regex = new RegExp(validationRegEx)
        setValidation(!answerText || regex.test(answerText))
    }, [validationRegEx, answerText])

    const handleSubmit = useCallback<any>(() => {
        if (!validation || !answerText) return;
        onChange({
            target: {
                value: {
                    answerText: answerText
                },
            },
        }, label)
    }, [
        onChange,
        validation,
        answerText,
        label,
    ])


    useEffect(() => {
        if (value) onSubmit()
    }, [
        onSubmit,
        value
    ])


    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>
                </div>

                <div className={classes.inputWrapper}>
                    <textarea
                        className={[classes.textArea, validation === false ? classes.errorValidationBorder : ''].join(" ")}
                        onInput={(event) => {
                            setAnswerText(event.currentTarget.value)
                        }}
                    />
                    {
                        validation !== false && helpText && <span className={classes.helpText}>{t(helpText)}</span>
                    }
                    {
                        validation === false &&
                        <span className={classes.errorValidationText}>{`${t(helpText)} ${t(validationErrorMessage)}`}</span>
                    }
                </div>

                <div className={classes.buttonBox}>
                    <Button
                        disabled={!answerText || !validation}
                        text={t("NEXT")}
                        onClick={() => handleSubmit()}
                    />

                </div>
                {
                    skippable && <SkipButton
                        onClick={onSubmit}
                    />
                }
            </div>
        </div>
    )
}

export default TextValidation
