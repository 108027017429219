import React, {useEffect, useMemo, useState} from 'react'

import Button from "../../Button";
//@ts-ignore
import {dateToStr, Loading, requestBusinessObjects, useUserLocations} from "@skycell-ag/shared-components";
import useStyles from "./TemperatureInfo.style";
import {useTranslation} from "react-i18next";
import ContainerValidationErrorMessage from "../../ContainerValidationErrorMessage";
import {Order} from "app/utils/dataTypes";
import TemperatureInfoContents from "./TemperatureInfoContents";
import LoadingSkyCell from 'app/components/LoadingSkyCell';

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string,
        orderNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    onReturn: (...arg: any) => any
}
const Buttons = {
    'NOW': 'TEMPERATURE.NOW',
    '24h': 'TEMPERATURE.24h',
    '7d': 'TEMPERATURE.7d',
    '14d': 'TEMPERATURE.14d',
    'SHIPMENT': 'TEMPERATURE.SHIPMENT',
}
import {
    useUserRoles,
} from '@skycell-ag/auth'
import {SSI_PROCESS_EXECUTION} from "../../../screens/MainMenu/MainMenu";
import useMakeRequest from "../../../hooks/useMakeRequest";
import moment from "moment";
import {dateTimeMaskTimestamp} from "../../../utils/constants";
import dateToISO from "../../../utils/dateToISO";

export enum TEMPERATURE_RIGHTS {
    SSI_TEMP_READOUT_SHIPMENT = 'SSI_TEMP_READOUT_SHIPMENT',
    SSI_TEMP_READOUT_PERIOD_RESTRICTED = 'SSI_TEMP_READOUT_PERIOD_RESTRICTED',
    SSI_TEMP_READOUT_PERIOD_FULL = 'SSI_TEMP_READOUT_PERIOD_FULL',
    SSI_TEMP_READOUT_LAST_MEASURED = 'SSI_TEMP_READOUT_LAST_MEASURED',
}

const dateRanges = {
    '24h': 1,
    '7d': 7,
    '14d': 14,
}

const TemperatureInfo = ({
                             onReturn,
                             value: {
                                 serialNumber,
                                 orderNumber
                             }
                         }: Props) => {

    const classes = useStyles()
    const {t} = useTranslation()
    const [buttonPressed, setButtonPressed] = useState<string>("NOW");
    const [loading, setLoading] = useState<boolean>(false);
    const [currentOrder, setCurrentOrder] = useState<Order>()
    const [sensorDataLoading, setSensorDataLoading] = useState(false)
    const roles = useUserRoles()
    const getOrderRequest = useMakeRequest('GET')


    useEffect(() => {
        if (orderNumber) {
            (async () => {
                    setLoading(true);
                    const result = await getOrderRequest(`order/${orderNumber}`);
                    if (result?.data) setCurrentOrder(result?.data || null);
                    setLoading(false);

                }
            )()
        }

    }, [orderNumber])

    const expectedLease = useMemo(() => {
        return (!currentOrder?.leaseEndTimestamp && !currentOrder?.shipmentEnd) ? moment(
            Math.max(
                moment(currentOrder?.billing?.baseLeaseUntil, 'DD.MM.YYYY')
                    .set({hour: 23 + new Date().getTimezoneOffset() / 60, minute: 59})
                    .valueOf(),
                moment()
                    .add({hour: 23 + new Date().getTimezoneOffset() / 60, minute: 59})
                    .valueOf(),
            )
        ).utc(true) : null
    }, [currentOrder])

    const [fromDate, toDate] = useMemo(() => {
        let from, to;
        if (buttonPressed === 'SHIPMENT') {
            const {shipmentStart, shipmentEnd} = currentOrder;
            const {leaseStartTimestamp, leaseEndTimestamp} = currentOrder?.billing;

            const start = shipmentStart || leaseStartTimestamp;
            const end = shipmentEnd || leaseEndTimestamp;
            [from, to,] = [
                moment(start, dateTimeMaskTimestamp).utc(false),
                (end && moment(end, dateTimeMaskTimestamp).utc(false))
                || expectedLease
            ];
        } else {
            [from, to,] = [
                dateToISO(moment().utc(false).subtract({day: dateRanges[buttonPressed]})),
                dateToISO(moment().utc(false))
            ];
        }
        return [from, to]

    }, [
        currentOrder,
        buttonPressed,
        expectedLease
    ])


    const currentButtons = useMemo<any[]>(() => {
        const buttonsSet = new Set()


        if (roles.includes(TEMPERATURE_RIGHTS.SSI_TEMP_READOUT_LAST_MEASURED)) {
            buttonsSet.add('NOW')
        }
        if (roles.includes(TEMPERATURE_RIGHTS.SSI_TEMP_READOUT_PERIOD_RESTRICTED)) {
            ["NOW", '24h'].forEach(it => buttonsSet.add(it))
        }
        if (roles.includes(TEMPERATURE_RIGHTS.SSI_TEMP_READOUT_PERIOD_FULL)) {
            ["NOW", '24h', '7d', '14d'].forEach(it => buttonsSet.add(it))
        }

        if (currentOrder?.billing?.leaseStartTimestamp && roles.includes(TEMPERATURE_RIGHTS.SSI_TEMP_READOUT_SHIPMENT)) {
            buttonsSet.add('SHIPMENT')
        }
        return Array.from(buttonsSet)
    }, [currentOrder, roles])


    return (
        <div className={classes.container}>


            {loading && <LoadingSkyCell/>}
            {
                orderNumber ? <TemperatureInfoContents
                        serialNumber={serialNumber}
                        order={currentOrder}
                        buttonPressed={buttonPressed}
                        setSensorDataLoading={setSensorDataLoading}
                        fromDate={fromDate}
                        toDate={toDate}
                        expectedLease={expectedLease}
                    /> :
                    <ContainerValidationErrorMessage
                        serialNumber={serialNumber}
                        cancel={false}
                    />
            }


            <div className={classes.buttonsContainer}>
                {buttonPressed && orderNumber &&
                    <div className={classes.buttonsRow}>
                        {
                            currentButtons.map(button => {
                                return <Button
                                    key={`button_in_row_${button}`}
                                    upperCase={true}
                                    classNames={classes.button}
                                    text={t(Buttons[button])}
                                    loading={sensorDataLoading}
                                    onClick={() => setButtonPressed(button)}
                                    ghost={buttonPressed !== button}
                                />
                            })
                        }
                    </div>
                }

                <div className={classes.buttonFullWidth}>
                    <Button
                        text={t("SCAN_OTHER_CONTAINER")}
                        onClick={onReturn}
                    />
                </div>
            </div>

        </div>
    )
}

export default TemperatureInfo
