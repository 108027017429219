import React from 'react'
import {
    useTranslation,
} from 'react-i18next'

import getAssetBlobUrl from 'app/utils/getGlobalAssetUrl'

import useStyles from './ErrorScreen.style'

type Props = {
    title?: string
}
const ErrorScreen = ({
                            title = 'NO_ACCESS_RIGHTS'
                        }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()

    const NoAccessRightsImage = getAssetBlobUrl('skyship/images/error_skycenter_sorry.svg')

    return (
        <div
            className={classes.container}
        >
            <img
                className={classes.img}
                src={NoAccessRightsImage}
                alt="error_skycenter_sorry"
            />
            <span>
                {t(title)}
            </span>
        </div>
    )
}

export default ErrorScreen
