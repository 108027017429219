import axios from 'axios'

const {
    REACT_APP_SKYMIND_API: apiUrl,
} = process.env

const getConfig = (token) => {
    return ({
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

const instance = axios.create({
    baseURL: `${apiUrl}/sensor-data-access/v1`,

})

const getCartaData = async (token, serialNumber, from, to) => {
    try {
        const config = getConfig(token)
        const immutableQueryParams = 'loggerType=CARTASENSE&sensorType=TEMPERATURE&position=AMBIENT&position=INTERNAL'

        const url = `container?serialNumber=${serialNumber}&from=${from}&to=${to}&${immutableQueryParams}`

        return await instance.get(url, config)
    } catch (error) {
        global.console.error(error)
    }
    return null
}
const getTemperatureInRange = async (token, serialNumber) => {
    try {
        const config = getConfig(token)

        const url = `currenttemperatureinrange?serialNumber=${serialNumber}`

        return await instance.get(url, config)
    } catch (error) {
        global.console.error(error)
    }
    return null
}

const getSensorData = async ({
                                 token,
                                 serialNumber,
                                 loggers,
                                 from,
                                 to,
                                 door = false
                             }) => {
    try {
        const config = getConfig(token)
        const positions = ["INTERNAL"]
        const dataTypes = ["TEMPERATURE"]

        if (door) {
            dataTypes.push('DOOR')
        }
        const url = `container/${serialNumber}/custom`

        return await instance.post(url, {
            // timestampFormat: "UNIX_EPOCH",
            positions,
            loggers,
            from,
            to,
            dataTypes,
        }, config)
    } catch (error) {
        global.console.error(error)
    }
    return null
}


export default {
    getTemperatureInRange, getCartaData, getSensorData
}
