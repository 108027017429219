import {
    useJWTToken,
    useAuth,
} from '@skycell-ag/auth'
import queryRetry from 'app/utils/queryRetry/queryRetry'
import {
    useQuery,
} from 'react-query'

const useQueryGetUserContacts = (queryFn, options = {}) => {
    const {
        user,
    } = useAuth()

    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getContact',
            {
                token,
            },
        ],
        queryFn,
        enabled: Boolean(user),
        refetchOnWindowFocus: false,
        retry: queryRetry,
        ...options,
    })
}

export default useQueryGetUserContacts
