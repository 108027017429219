import {
    useJWTToken,
} from '@skycell-ag/auth'
import {
    useQuery,
} from 'react-query'


import getProcesses from './getProcesses'
import queryRetry from '../../utils/queryRetry/queryRetry'

function useProcesses(addressId, options = {}) {
    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getProcesses',
            {
                location: addressId,
                token,
            },
        ],
        queryFn: getProcesses,
        enabled: Boolean(addressId),
        refetchOnWindowFocus: false,
        retry: queryRetry,
        ...options,
    })
}

export default useProcesses
