import React, {
    useEffect,
} from 'react'
import {
    Switch,
    Route,
    useRouteMatch,
} from 'react-router-dom'

import MainMenu from 'app/screens/MainMenu'
import Layout from 'app/components/Layout'
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage'

import Process from 'app/screens/Process'
import getEnviroment from 'app/utils/getEnviroment'
import LoadingSkyCell from "../components/LoadingSkyCell";
import HeaderNavigationProvider from "../HeaderNavigationProvider";

const Root = () => {
    const {
        path,
    } = useRouteMatch()

    useEffect(() => {
        const env = getEnviroment()

        if (env) {
            document.title = `SkyShip ${env}`
        }
    }, [])

    return (
            <Switch>
                <Layout>
                    <Route
                        exact
                        path={path}
                    >
                        <MainMenu/>
                    </Route>

                    <Route
                        path={`${path}:process`}
                    >
                        <Process/>
                    </Route>

                    <Route
                        path={`${path}notfound/:id`}
                    >
                        <ContainerValidationErrorMessage/>
                    </Route>
                </Layout>
            </Switch>
    )
}

export default Root
