import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import {
    Loading,
} from '@skycell-ag/shared-components'

import FieldTitle from 'app/components/FieldTitle'
import PhotoPicker from 'app/components/PhotoPicker'

import useAssetNumber from 'app/hooks/useAssetNumber'
import useSaveAttachment from 'app/hooks/useSaveAttachment'

import LoadingSkyCell from 'app/components/LoadingSkyCell'
import {useTranslation} from "react-i18next";
import useStyles from "./Picture.style";
import Button from "../../Button";
import SavingError from "../../SavingError";
import AddComment from "../../AddComment";

type Props = {
    type: string,
    title: string,
    subTitle: string,
    label: string,
    skippable?: boolean,
    value: any,
    process: string,
    onChange: (...args: any) => any,
    onSubmit: (...args: any) => any,
}


const APP_NAME = 'Skyship'

const Picture = ({
                     type,
                     title,
                     subTitle,
                     label,
                     skippable = false,
                     process,
                     onChange,
                     onSubmit,
                     value = null
                 }: Props) => {
    const [
        picture,
        setPicture,
    ] = useState(null)

    const classes = useStyles()
    const [assetNumber] = useAssetNumber()
    const [description, setDescription] = useState(null)

    const {t} = useTranslation()
    const {
        data: attachment,
        mutate: saveAttachment,
        isLoading,
        isSuccess,
        error:saveAttachmentError
    } = useSaveAttachment()

    const handleChange = useCallback((input) => {
        setPicture(input.target.value)
    }, [setPicture])

    const handleSubmitPicture = useCallback(() => {
        saveAttachment({
            file: picture,
            fileName: `${APP_NAME}-${process}-AssetNb_${assetNumber}-${type}-${label}.png`,
        })
    }, [
        saveAttachment,
        picture,
        process,
        assetNumber,
        type,
        label,
    ])
    const handleSubmitComment = useCallback(() => {
        onChange({
            target: {
                value: {
                    attachment,
                    description,
                },
            },
        }, label)
    }, [
        attachment,
        description,
        label,
    ])


    useEffect(() => {
        if (value?.picture?.id) {
            onSubmit()
        }
    }, [
        value,
        onSubmit
    ])


    if (isLoading) {
        return <LoadingSkyCell/>
    }
    if(saveAttachmentError) {
        return <SavingError
            title={"CANT_SAVE_PICTURES"}
            onTryAgain={handleSubmitPicture}
        />
    }

    if (attachment) {
        return <>
            <AddComment
                onNext={handleSubmitComment}
                onTextChanged={setDescription}
                text={description}
                onSkip={handleSubmitComment}
            />
        </>
    }

    return (
        <>
            <FieldTitle
                title={picture ? t("REVIEW_PICTURE") : title}
                subTitle={subTitle}
            />

            <div
                className={classes.tabContent}
            >
                <PhotoPicker
                    label={label}
                    value={picture}
                    skippable={skippable}
                    onChange={handleChange}
                    onSubmit={handleSubmitPicture}
                    onSkip={skippable ? onSubmit : null}
                />
            </div>

        </>
    )
}

export default Picture
