import React from 'react'
import CheckIcon from '@material-ui/icons/Check'
import {
    useTranslation,
} from 'react-i18next'

import Button from 'app/components/Button'

import useStyles from './BarcodeDecodedMessage.style'

type Props = {
    value: string,
    onSubmit: (...arg: any) => any,
    identifiedMessage: string,
    onRepeat?: any
}

const BarcodeDecodedMessage = ({
    value,
    onSubmit,
    identifiedMessage,
    onRepeat,
}: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()

    return (
        <div
            className={classes.container}
        >
            <div className={classes.message}>
                <h2
                    className={classes.messageTitle}
                >
                    { t(identifiedMessage) }

                </h2>

                <CheckIcon

                    className={classes.icon}
                />
            </div>

            <h3
                className={classes.value}
            >
                { value }
            </h3>

            <div
                className={classes.controls}
            >
                {
                    onRepeat && <Button
                        text={t('REPEAT')}
                        onClick={onRepeat}
                    />
                }
                <Button
                    text={t('NEXT')}
                    onClick={onSubmit}
                />

            </div>
        </div>
    )
}


export default BarcodeDecodedMessage
