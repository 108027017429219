import { useJWTToken } from '@skycell-ag/auth'
import abstractService from "../../utils/abstractService";


const useFilterObject = (objectName: string, endpoint?: string | null, version?: string | null) => {
    const token = useJWTToken()
    const { filterObject } = abstractService(endpoint, version)

    return filterObject(objectName, token)
}

export default useFilterObject
