import React, {
    useCallback,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import AcceptButton from 'app/components/AcceptButton'
import FieldTitle from 'app/components/FieldTitle'

import useStyles from './DamageAssessment.style'

type Props = {
    picture: string
    label: string,
    onSubmit: (...arg:any) => any,
}

const DamageAssessment = ({
    picture,
    label,
    onSubmit,
}: Props) => {
    const {
        t,
    } = useTranslation()
    const classes = useStyles()

    const handleCancel = useCallback(() => {
        onSubmit(false)
    }, [onSubmit])

    const handleSubmit = useCallback(() => {
        onSubmit(true)
    }, [onSubmit])

    return (
        <>
            <FieldTitle
                title={t('CHECK_DAMAGE')}
            />

            <div
                className={classes.tabContent}
            >
                <div className={classes.imageContainer}>
                    <img
                        className={classes.image}
                        src={picture}
                        alt={label}
                    />
                </div>

                <div
                    className={classes.controls}
                >
                    <AcceptButton
                        submitBtnText={t('OK')}
                        cancelBtnText={t('DAMAGED')}
                        onCancel={handleCancel}
                        onSubmit={handleSubmit}
                    />
                </div>
            </div>
        </>
    )
}


export default DamageAssessment
