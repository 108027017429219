import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => {
    return {
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25,
            height: '100%',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignItems: 'center',
            height: '100%',
            color: '#000000DE'
        },
        content: {
            display: 'flex',
        },
        title: {
            font: '36px Roboto, sans-serif',
            fontWeight: 200,
            wordBreak: 'break-word',
            textAlign:'center'

        },
        checkIcon: {
            color: theme.palette.primary[200],
            height: '70px !important',
            width: '70px !important',
        },
    }
})

export default useStyles
