import {makeStyles} from "@material-ui/core";


const paddingX = 21
const paddingY = 10
const menuItemWidth = 260
const activeBorderWidth = 12
export const useStyles = makeStyles(theme => ({
    item: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'white',
        padding: `${paddingY}px ${paddingX}px`,
        width: menuItemWidth,
        transition: '200ms ease'
    },
    active: {
        borderLeft: `${activeBorderWidth}px white solid`,
        width: menuItemWidth - activeBorderWidth,
        '& img': {
            marginLeft:`${-activeBorderWidth}px`
        }
    },
    menuItem: {
        height: 50,
        backgroundColor: theme.palette.primary[100],
        borderBottom: '1px solid',
        font: 'normal normal 300 21px/25px Roboto'

    },
    menuHeader: {
        height: 30,
        backgroundColor: theme.palette.primary[400],
        font: 'normal normal 300 21px/25px Roboto'
    },
    icon: {
        height: 35,
        width: 35,
        marginRight: 10,

}

}))
