import useProcesses from '../useProcesses';
import {Process} from "../../utils/dataTypes";
import {accessibleStaticProcesses} from "../useProcesses/staticProcesses";
import {
    useUserRoles,
} from '@skycell-ag/auth'
import useUserLocation from '../useUserLocation';

type FetchData = {
    data: Process[]
    isLoading: boolean
    error: any
}


function useFetchProcesses(hasRoles?) {
    const {
        data: location,
        isLoading: isLocationLoading,
        error: locationError,
    }:any = useUserLocation({
        refetchOnMount: false,
    })


    const roles = useUserRoles()

    const {
        data: processes,
        isLoading: isProcessesLoading,
        error: processesError,
    }:FetchData|null = useProcesses(location?.id, {
        refetchOnMount: false,
        cacheTime:5,
        enabled: Boolean(location?.id && hasRoles),
    })

    if (!isLocationLoading && (locationError || !location?.id)) {
        console.error('Failed to fetch required location')
    }

    if (!isProcessesLoading && processesError) {
        console.error('Failed to fetch required processes')
    }

    return {
        isLocationLoading,
        isProcessesLoading,
        locationsError: locationError || !location?.id,
        processesError,
        location,
        processes:[...(processes || []), ...accessibleStaticProcesses(roles || [])],
    }
}

export default useFetchProcesses
