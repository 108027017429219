import React, {useCallback, useEffect, useState} from 'react'
import FieldTitle from "../../FieldTitle";
import styles from "../SerialNumber/SerialNumber.module.scss";
import getContainerNumberFromGRAI from "../../../utils/getContainerNumberFromGRAI";
import {
    Loading,
    // @ts-ignore
    Radio, requestBusinessObjects, useUserLocations,
} from '@skycell-ag/shared-components'
import {useTranslation} from "react-i18next";
import useReaderSwitch from "../../../hooks/useReaderSwitch";
import {
    useJWTToken,
} from '@skycell-ag/auth'
import MultiReader from 'app/components/MultiReader';
import useUserLocation from "../../../hooks/useUserLocation";
import {DevPlug} from "../../../utils/DevComponents";


type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const RawScan = ({
                     title = 'SCAN_CONTAINER_CODE_RAW',
                     subTitle = '',
                     label,
                     skippable = false,
                     onChange,
                     onSubmit,
                 }: Props) => {


    const {
        t,
    } = useTranslation()

    const token = useJWTToken()

    const [scannedCode, setScannedCode] = useState(null)
    const [orderNumber, setOrderNumber] = useState(null)

    const {
        data: location,
        isLoading: isLocationsLoading,
    }: any = useUserLocation({
        refetchOnMount: false,
    })


    useEffect(() => {
        if (!scannedCode) return;

        onChange({
            target: {
                value: {
                    serialNumber: scannedCode,
                    orderNumber,
                }
            },
        }, "CHECK_TEMPERATURE")
        onSubmit()
    }, [orderNumber])

    useEffect(() => {
        if (location && scannedCode) {
            (
                async () => {
                    try {
                        const res = await requestBusinessObjects({
                            method: 'GET',
                            url: `orderfromselectedasset?assetNumber=${scannedCode}&locationId=${3}&assetType=CONTAINER`,
                            token,
                        })
                        setOrderNumber(res?.data?.orderNumber)

                    } catch (e) {
                        setOrderNumber('')
                    }
                }
            )()
        }
    }, [location, scannedCode])

    return <>
        <FieldTitle
            title={t(title)}
            subTitle={subTitle}
        />

        <DevPlug
            devFunction={setScannedCode}
            devFunctionName={'setSerial'}
        />

        {
            isLocationsLoading ? <Loading className={''}/> :
                <>
                    <div
                        className={styles.tabContent}
                    >
                        <MultiReader
                            onScan={setScannedCode}
                            label={label}
                            skippable={skippable}
                            onSkip={onSubmit}
                        />
                    </div>
                </>
        }

    </>
}

export default RawScan
