import React, {
    useState,
    useCallback,
    useEffect,
} from 'react'
import CheckIcon from '@material-ui/icons/Check'
import {
    useTranslation,
} from 'react-i18next'
import {
    dateToStr,
} from '@skycell-ag/shared-components'

import Button from 'app/components/Button'

import useStyles from './ChoiceSingle.style'
import {Choice} from "../../../utils/dataTypes";
import {FormControlLabel, Radio} from "@material-ui/core";
import SkipButton from "../../SkipButton";

type Props = {
    title: string,
    label: string,
    value?: {
        confirmed: boolean
        confirmationOnZoned: string
    },
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    choices: Choice[]
    skippable: boolean
}


const ChoiceSingle = ({
                          title,
                          label,
                          value = null,
                          onChange,
                          onSubmit,
                          choices = [],
                          skippable = false
                      }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()
    const [checkedValue, setCheckedValue] = useState(null)


    const handleSubmit = useCallback(() => {
        if(!skippable && !checkedValue) {
            return
        }

        onChange({
            target: {
                value: {
                    selectedChoiceIdentifier: checkedValue
                },
            },
        }, label)
    }, [
        skippable,
        onChange,
        checkedValue,
        label,
    ])

    useEffect(() => {
        if (value) onSubmit()
    }, [
        onSubmit,
        value
    ])


    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>
                </div>

                <div className={classes.choices}>
                    {
                        choices.map(choice => {
                            return <FormControlLabel
                                key={`CHOICE_${choice.choiceIdentifier}`}
                                className={classes.choice}
                                checked={checkedValue === choice.choiceIdentifier}
                                onChange={() => {
                                    setCheckedValue(choice.choiceIdentifier)
                                }}
                                label={
                                    <span className={classes.checkboxLabel}>{t(choice.choiceLabel)}</span>
                                }
                                control={
                                    <Radio
                                        size={"small"}
                                        className={classes.checkbox}
                                    />
                                }
                            />
                        })
                    }
                </div>

                <Button
                    text={t('NEXT')}
                    onClick={handleSubmit}
                />
                {
                    skippable && <SkipButton
                        onClick={onSubmit}
                    />
                }

            </div>
        </div>
    )
}

export default ChoiceSingle
