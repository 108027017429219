import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '30px 20px 30px 20px',
        height: '100%',
        width: '100%',
    },
    title: {
        font: '36px Roboto, sans-serif',
        fontWeight: 200,
        textAlign: 'center',
        margin: 0,
    },
    divider: {
        flex:1
    },
    img: {
        width: '100%',
        height: '40vh',
        objectFit: 'contain',

},
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row'
    }
})

export default useStyles
