import React, {useContext, useEffect} from 'react'
import HeaderNavigationContext from "../../context/HeaderNavigationContext";

type Props = {
    onClick?: (...arg: any) => any,
}


const SkipButton = ({
    onClick = () => {},
}: Props) => {
    const headerNavigationContext = useContext(HeaderNavigationContext)

    useEffect(() => {
        headerNavigationContext.setOnSkip(onClick)
        return () => {
            headerNavigationContext.setOnSkip(null)
        }
    }, [onClick])

    return <></>
}


export default SkipButton
