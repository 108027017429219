import React, {
    useEffect,
    useContext,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'
import {
    // @ts-ignore
    Radio,
    Loading,
    // @ts-ignore
    useUserLocations,
} from '@skycell-ag/shared-components'

import FieldTitle from 'app/components/FieldTitle'
import BarcodeDecodedMessage from 'app/components/BarcodeReader/BarcodeDecodedMessage'
import ContainerValidationErrorMessage from 'app/components/ContainerValidationErrorMessage'
import getContainerNumberFromGRAI from 'app/utils/getContainerNumberFromGRAI'
import useOrderFromSelectedAsset from 'app/hooks/useOrderFromSelectedAsset'

import AssetNumberContext from 'app/context/AssetNumberContext'

import useReaderSwitch from 'app/hooks/useReaderSwitch'

import styles from './SerialNumber.module.scss'
import LoadingSkyCell from "../../LoadingSkyCell";
import MultiReader from "../../MultiReader";

type Props = {
    process: string,
    title?: string,
    value?: {
        serialNumber: string
    },
    subTitle?: string,
    label: string,
    skippable?: boolean,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}


const SerialNumber = ({
    title = 'SCAN_CONTAINER_CODE',
    subTitle = '',
    label,
    process,
    skippable = false,
    value = null,
    onChange,
    onSubmit,
}: Props) => {
    const {
        t,
    } = useTranslation()


    const [
        assetNumber,
        setAssetNumber,
    ] = useContext(AssetNumberContext)

    const {
        data: locations,
        isLoading: isLocationsLoading,
        error: locationsError,
    } = useUserLocations({
        refetchOnMount: false,
    })

    const {
        data,
        error,
        isLoading,
    }: any = useOrderFromSelectedAsset({
        assetNumber,
        process,
        assetType: 'CONTAINER',
        locationId: locations?.[0]?.id,
    })

    useEffect(() => {
        if (!assetNumber && value?.serialNumber) {
            setAssetNumber(value?.serialNumber)
        }
    }, [
        value,
        assetNumber,
        setAssetNumber,
    ])

    if (isLoading || isLocationsLoading) {
        return <LoadingSkyCell />
    }

    if (locationsError || error || data?.length === 0) {
        return (
            <ContainerValidationErrorMessage
                serialNumber={assetNumber}
                error={error.response?.data[0]}
                onRepeat={() => {
                    setAssetNumber(null)
                    onChange({
                        target: {
                            value: "",
                        },
                    }, label)
                }}
            />
        )
    }

    if (value?.serialNumber) {
        return (
            <BarcodeDecodedMessage
                value={value?.serialNumber}
                onSubmit={onSubmit}
                identifiedMessage="CONTAINER_IDENTIFIED"
                onRepeat={() => {
                    setAssetNumber(null)
                    onChange({
                        target: {
                            value: "",
                        },
                    }, label)
                }}

            />
        )
    }

    return (
        <>
            <FieldTitle
                title={t(title)}
                subTitle={subTitle}
            />

            <div
                className={styles.tabContent}
            >
                <MultiReader
                    label={label}
                    skippable={skippable}
                    onScan={(value) => {
                        onChange({
                            target: {
                                value: value,
                            },
                        }, label)

                    }}
                    onSkip={onSubmit}
                />
            </div>
        </>
    )
}


export default SerialNumber
