import React, {
    useCallback,
    useEffect, useMemo, useState,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import Button from 'app/components/Button'

import useStyles from './Text.style'
import {TextField} from "@material-ui/core";
import SkipButton from "../../SkipButton";

type Props = {
    title: string,
    label: string,
    value?: any,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
    helpText: string
    skippable: boolean

}


const Text = ({
                  title,
                  label,
                  value = null,
                  onChange,
                  onSubmit,
                  helpText,
                  skippable = false
              }: Props) => {
    const classes = useStyles()
    const {
        t,
    } = useTranslation()
    const [answerText, setAnswerText] = useState("")


    const handleSubmit = useCallback<any>(() => {
        onChange({
            target: {
                value: {
                    answerText: answerText
                },
            },
        }, label)
    }, [
        onChange,
        answerText,
        label,
    ])


    useEffect(() => {
        if (value) onSubmit()
    }, [
        onSubmit,
        value
    ])


    return (
        <div
            className={classes.tabContent}
        >
            <div
                className={classes.container}
            >
                <div
                    className={classes.content}
                >
                    <h2
                        className={classes.title}
                    >
                        {t(title)}
                    </h2>
                </div>

                <div className={classes.inputWrapper}>
                    <textarea
                        className={classes.textArea}
                        onInput={(event) => {
                            setAnswerText(event.currentTarget.value)
                        }}
                    />
                    {
                        helpText && <span className={classes.helpText}>{helpText}</span>
                    }
                </div>

                <div className={classes.buttonBox}>
                    <Button
                        text={t("NEXT")}
                        onClick={() => handleSubmit()}
                    />

                </div>
                {
                    skippable && <SkipButton
                        onClick={onSubmit}
                    />
                }
            </div>
        </div>
    )
}

export default Text
