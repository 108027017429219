import React from 'react'

import {
    createTheme, MuiThemeProvider,
} from '@material-ui/core/styles'
import skyTheme from '@skycell-ag/theme'

import {
    StylesProvider as Provider,
    jssPreset,
    createGenerateClassName,
} from '@material-ui/styles'
import {
    create,
} from 'jss'
import jssExtend from 'jss-extend'
import jssNested from 'jss-nested'
import {ReactNodeLike} from "prop-types";

type Props = {
    children: ReactNodeLike,
}
const jss = create({
    ...jssPreset(),
    plugins: [
        ...jssPreset().plugins,
        jssExtend(),
        jssNested(),
    ],
    insertionPoint: document.getElementById('jss-insertion-point'),
})

const generateClassName = createGenerateClassName({
    productionPrefix: 'skycenter',
    disableGlobal: true,
})


const theme = createTheme({
    ...skyTheme,

    breakpoints: {
        values: {
            'xl': 2560,
            'lg': 1600,
            'md': 1000,
            'sm': 500,
            'xs': 240,
        },
    },
    overrides: {
        MuiCircularProgress: {
            circle: {
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                color: '#747474',
                '&$checked': {
                    color: '#61C6E9',
                },
            },
        },
        MuiRadio: {
            colorSecondary: {
                color: '#747474',
                '&$checked': {
                    color: '#61C6E9',
                },
            },
        },

    }


})

const StylesProvider = ({
                            children,
                        }: Props) => {
    return (
        <Provider
            jss={jss}
            generateClassName={generateClassName}
        >
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </Provider>
    )
}


export default StylesProvider
