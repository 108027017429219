import React, {
    useState,
    useCallback,
    useContext,
    useMemo, useEffect,
} from 'react'
import {
    useTranslation,
} from 'react-i18next'

import FieldTitle from 'app/components/FieldTitle'
import PhotoPicker from 'app/components/PhotoPicker'
import useFindMatchedEvidence from 'app/hooks/useFindMatchedEvidence'
import ComparePhotos from 'app/components/ComparePhotos/ComparePhotos'
import AssetNumberContext from 'app/context/AssetNumberContext'

import useSaveAttachment from 'app/hooks/useSaveAttachment'

import DamageAssessment from 'app/components/DamageAssessment'
import LoadingSkyCell from "../../LoadingSkyCell";
import useStyles from "./PictureComparison.style";
import Button from "../../Button";
import SavingError from "../../SavingError";
import ConditionScreen from "./ConditionScreen";
import AddComment from "../../AddComment";

type Props = {
    type: string,
    title: string,
    subTitle: string,
    label: string,
    skippable?: boolean,
    value: any,
    process: string,
    onChange: (...arg: any) => any,
    onSubmit: (...arg: any) => any,
}

const APP_NAME = 'Skyship'

type Condition = "COMPARISON_SKIPPED" | "DAMAGE_CHECKED" | "PICTURES_COMPARED"
type ConditionData = {
    condition: Condition,
    passed: boolean
}
const PictureComparison = ({
                               type,
                               process,
                               title,
                               value = null,
                               subTitle,
                               label,
                               skippable = false,
                               onChange,
                               onSubmit,
                           }: Props) => {
    const {
        t,
    } = useTranslation()
    const [assetNumber] = useContext(AssetNumberContext)

    const classes = useStyles()
    const {
        data,
    } = useFindMatchedEvidence({
        assetNumber,
    })
    const [conditionData, setConditionData] = useState<ConditionData>({
        condition: null,
        passed: false
    })

    const [description, setDescription] = useState(null)
    const [comparisonMatching, setComparisonMatching] = useState(null)

    const sendingStep = useMemo(() => {
        let step = null

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < data?.progressGroups?.length; i++) {
            const matchedStep = data?.progressGroups[i]?.steps.find(({
                                                                         stepName,
                                                                         stepType,
                                                                     }) => {
                return ((stepName === label) && (stepType === 'PICTURE'))
            })

            if (matchedStep) {
                step = matchedStep
                break
            }
        }

        return step
    }, [
        data,
        type,
        label,
    ])

    const [
        picture,
        setPicture,
    ] = useState(null)

    const [
        accepted,
        setAcceptedStatus,
    ] = useState(false)

    const {
        data: attachment,
        mutate: saveAttachment,
        isLoading,
        error: saveAttachmentError
    } = useSaveAttachment()

    const handleChange = useCallback((input) => {
        setPicture(input.target.value)
    }, [setPicture])

    const handleComparisonSubmit = useCallback((comparisonMatching = false, conditionType: Condition) => {
        setComparisonMatching(comparisonMatching)
        setConditionData({
            condition: conditionType,
            passed: false,
        })
    }, [])

    const handleSubmit = useCallback(() => {
        onChange({
            target: {
                value: {
                    attachment,
                    comparisonMatching,
                    description,
                },
            },
        }, label)

    }, [
        attachment,
        comparisonMatching,
        description,
        label,
        onChange,
        onSubmit,
    ])

    useEffect(() => {
        if (value?.picture?.id) {
            onSubmit()
        }
    }, [
        value,
        onSubmit
    ])


    const handleSubmitAttachment = useCallback(() => {
        setAcceptedStatus(true)
        saveAttachment({
            file: picture,
            fileName: `${APP_NAME}-${process}-AssetNb_${assetNumber}-${type}-${label}.png`,
        })
    }, [
        picture,
        process,
        type,
        label,
        assetNumber,
        saveAttachment,
    ])

    if(saveAttachmentError) {
        return <SavingError
            title={"CANT_SAVE_PICTURES"}
            onTryAgain={handleSubmitAttachment}
        />
    }

    if (isLoading) {
        return <LoadingSkyCell/>
    }

    if (conditionData.passed) {
        return <ConditionScreen
            onProceed={conditionData.condition === "COMPARISON_SKIPPED" ? onSubmit : handleSubmit}
            title={conditionData.condition}
        />
    }

    if (comparisonMatching !== null) {

        return <>
            <AddComment
                text={description}
                onNext={() => setConditionData((prev) => ({...prev, passed: true}))}
                onTextChanged={setDescription}
                onSkip={() => setConditionData((prev) => ({...prev, passed: true}))}

            />
        </>
    }


    if (accepted) {
        if (!sendingStep || !sendingStep?.userInput) {
            return (
                <DamageAssessment
                    label={label}
                    picture={picture}
                    onSubmit={(bool) => handleComparisonSubmit(bool, "DAMAGE_CHECKED")}
                />
            )
        }

        if (sendingStep?.isAnswered) {
            return (
                <>
                    <FieldTitle
                        title={t('COMPARE_PICTURES')}
                        subTitle={subTitle}
                    />

                    <div
                        className={classes.comparisonContent}
                    >
                        <ComparePhotos
                            imageData={sendingStep?.userInput?.picture}
                            currentImage={picture}
                            label={label}
                            onSubmit={(bool) => handleComparisonSubmit(bool, "PICTURES_COMPARED")}
                        />
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <FieldTitle
                title={t(picture ? 'REVIEW_PICTURE' : title)}
                subTitle={subTitle}
            />

            <div
                className={classes.tabContent}
            >
                <PhotoPicker
                    label={label}
                    value={picture}
                    skippable={skippable}
                    onChange={handleChange}
                    onSubmit={handleSubmitAttachment}
                    onSkip={skippable ? () => {
                        setConditionData({
                            condition: "COMPARISON_SKIPPED",
                            passed: true
                        })
                    } : null}
                />
            </div>
        </>
    )
}

export default PictureComparison
