import React, {useMemo} from 'react'
import ReactApexChart from 'react-apexcharts'
import useScreenSize from "../../hooks/useScreenSize";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useStyles} from "./TemperatureChart.style";
import {ApexOptions} from "apexcharts";
import {momentAddOffset} from "../../utils/tools";


type Props = {
    data: any,
    containerType?: string,
    chartMounted: (chart?: any, options?: any) => void,
    excursionOn: string,
    leaseStartTimestamp: string,
    leaseEndTimestamp: string,
    shipmentStart: string,
    shipmentEnd: string,
    expectedLease: string,
}

const ContainerMinMaxTemp = {
    DF: [-80, -60],
    F: [-25, -15],
    C: [2, 8],
    CRT: [15, 25],
}

const getExcursionLine = (excursionOn: string) => ({
    x: moment(excursionOn, 'DD-MM-YYYY HH:mm:ss')
        .utc(true)
        .add({minute: moment().utcOffset()})
        .valueOf(),
    borderColor: '#D44848',
    strokeDashArray: 0,
    borderWidth: 2,
    label: {
        style: {
            background: 'none'
        },
        borderWidth: 0,
        offsetY: -10,
        offsetX: 16,
        text: 'Excursion',
    },
})
const DOOR_COLORS = ['#fa19dd', '#fd8fef']
const DANGER_COLOR = '#EDAE49'
const getDoorLine = (date: string, label = 'OPEN', index, t) => ({
    x: moment(date, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .add({minute: moment().utcOffset()})
        .valueOf(),
    strokeDashArray: 0,
    borderWidth: 2,
    borderColor: DOOR_COLORS[label === 'OPEN' ? 0 : 1],
})
const getCustomAnnotation = ({
                                 date,
                                 label,
                                 dateMask = 'DD-MM-YYYY HH:mm ZZ',
                                 offset = 0,
                                 offsetY = -10,
                                 dashed = false,
                                 background = 'white',
                                 utc = false
                             }) => ({
    x: momentAddOffset(moment(date, dateMask).utc(utc)).valueOf(),
    borderColor: '#747474',
    strokeDashArray: dashed ? 2 : 0,
    borderWidth: dashed ? 1 : 2,
    label: {
        style: {
            color: '#747474',
            background: background
        },
        offsetX: offset,
        offsetY: offsetY,
        borderWidth: 0,
        text: label,
    }
})

const TemperatureChart = ({
                              data = [],
                              containerType = 'CRT',
                              chartMounted = () => {
                              },
                              excursionOn,
                              leaseStartTimestamp,
                              leaseEndTimestamp,
                              shipmentStart,
                              shipmentEnd,
                              expectedLease,
                          }: Props) => {

    const {t} = useTranslation()
    const classes = useStyles()
    const setDateItem = (dateItem: number) => dateItem < 10 ? `0${dateItem}` : dateItem

    console.log({
        leaseStartTimestamp,
        leaseEndTimestamp,
        shipmentStart,
        shipmentEnd,
        expectedLease,
    })
    console.log({moment})
    const xAnnotations = useMemo(() => {
        const annotations = []
        if (excursionOn) {
            annotations.push(
                getExcursionLine(excursionOn)
            )
        }
        if (leaseStartTimestamp) {
            annotations.push(getCustomAnnotation(
                {
                    date: leaseStartTimestamp,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                        offset: 17,
                    label: t("LEASE_START")
                }))
        }
        if (shipmentStart) {
            annotations.push(getCustomAnnotation(
                {
                    date: shipmentStart,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                    offset: 17,
                    label: t("SHIPMENT_START")
                }))
        }
        if (expectedLease) {
            annotations.push(getCustomAnnotation(
                {
                    date: expectedLease,
                    dateMask: 'DD.MM.YYYY HH:mm',
                    label: t("EXPECTED_LEASE_END"),
                    offset: 14,
                    background: 'none',
                    utc: true,
                }))
            annotations.push(getCustomAnnotation(
                {
                    date: moment().utc(false).format('DD.MM.YYYY HH:mm'),
                    dateMask: 'DD.MM.YYYY HH:mm',
                    label: t("NOW"),
                    dashed: true,
                    offset: -1,
                    utc: true,
                }))
        }
        if (shipmentEnd) {
            annotations.push(getCustomAnnotation(
                {
                    date: shipmentEnd,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                    label: t("SHIPMENT_END"),
                    offset: 14,

                }))
        }
        if (leaseEndTimestamp) {
            annotations.push(getCustomAnnotation(
                {
                    date: leaseEndTimestamp,
                    dateMask: 'DD.MM.YYYY HH:mm:ss ZZ',
                    label: t("LEASE_END"),
                    offset: 14,
                }))
        }

        return annotations
    }, [
        excursionOn,
        leaseEndTimestamp,
        leaseStartTimestamp,
        shipmentStart,
        shipmentEnd,
        expectedLease,
    ])

    const doorAnnotations = useMemo(() => {
        return data
            .filter(it => it?.d?.[1])
            .map(it => ({date: it?.t, label: it?.d[1]}))
            .map((door, index) => getDoorLine(door.date, door.label, index, t)).reverse()
    }, [data])

    console.log({doorAnnotations})
    const options = useMemo<ApexOptions>((): ApexOptions => ({
        chart: {
            id: "basic-bar",
            events: {
                mounted: chartMounted
            },

        },
        legend: {
            show: true,
            position: 'bottom',
            showForSingleSeries: true,
            customLegendItems: [
                t("OPENED"),
                t("CLOSED"),
                t("ALLOWED_RANGE"),
            ],
            markers: {
                fillColors: [
                    DOOR_COLORS[0],
                    DOOR_COLORS[1],
                    DANGER_COLOR
                ]

            }
        },
        xaxis: {
            type: 'datetime',
            categories: data?.map(({t}) =>
                moment(t, 'YYYY-MM-DD HH:mm')
                    .utc(true)
                    .subtract({minute: new Date().getTimezoneOffset()})
                    .valueOf()),
        },
        stroke: {
            width: 2,
            curve: 'straight'
        },
        annotations: {
            xaxis: [...doorAnnotations, ...xAnnotations,],
            yaxis: [
                ...(ContainerMinMaxTemp[containerType]?.[0] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[0],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
                ...(ContainerMinMaxTemp[containerType]?.[1] ? [{
                    y: ContainerMinMaxTemp[containerType]?.[1],
                    borderColor: DANGER_COLOR,
                    borderWidth: 2,
                }] : []),
            ],

        },
        tooltip: {
            shared: true,
            x: {
                formatter: (date) => {
                    const d = new Date(date)
                    const month = setDateItem(d.getUTCMonth() + 1)
                    const day = setDateItem(d.getUTCDate())
                    const hour = setDateItem(d.getUTCHours())
                    const minutes = setDateItem(d.getUTCMinutes())
                    return `${d.getUTCFullYear()}-${month}-${day} ${hour}:${minutes}`
                }
            },
            y: {
                formatter: function (value: any, {series, seriesIndex, dataPointIndex, w}): string { //To remove Door part from tooltip
                    return isNaN(value) ? undefined : value
                }
            }
        },
        yaxis: {
            min: Math.min(ContainerMinMaxTemp[containerType]?.[0],
                Math.min(...data.filter(({d}) => d?.[0]).map(({d}) => d?.[0]))) - 2,
            max: Math.max(ContainerMinMaxTemp[containerType]?.[1],
                Math.max(...data.filter(({d}) => d?.[0]).map(({d}) => d?.[0]))) * 1.3,
        }
        ,
    }), [
        data,
        containerType,
        xAnnotations,
        doorAnnotations,
        chartMounted
    ])


    const series = useMemo(() => {
        return [{
            name: t('TEMPERATURE.INTERNAL_TEMPERATURE'),
            data: data.map(({d}) => d?.[0])
        }]
    }, [data, containerType])

    const {width, height} = useScreenSize()


    return <>
        <div className={classes.chartWrapper}>
            <ReactApexChart
                type={'line'}
                series={series}
                options={options}
                width={Math.min(width - 40, 600)}
                height={Math.min(height - 420, 600)}
            />
        </div>
    </>
}

export default TemperatureChart
