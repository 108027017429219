import {useQuery} from "react-query";
import queryRetry from "../../utils/queryRetry/queryRetry";
import useFilterFlatObject from "../useFilterFlatObject";
import {
    // @ts-ignore
    requestBusinessObjects
} from '@skycell-ag/shared-components'
import {
    useJWTToken,
} from '@skycell-ag/auth'



const getContactInfo = ({
                                 queryKey: [
                                     // eslint-disable-next-line no-unused-vars
                                     _key,
                                     data,
                                     token
                                 ],
                             }) => {
    return requestBusinessObjects({
        method: 'POST',
        url: `contact/flat/filter`,
        token,
        data,
    }).then((response) => {
        return response?.data?.items?.[0]
    })
}

const useContactInfo = (email, ...options) => {

    const token = useJWTToken()

    return useQuery({
        queryKey: [
            'getContactInfo',
            {
                start: 0,
                rows: 10,
                includeFilters: {
                    email: [email]
                }
            },
            token
        ],
        queryFn:getContactInfo,
        enabled: Boolean(email),
        refetchOnWindowFocus: false,
        retry: queryRetry,
        ...options,
    })

}


export default useContactInfo
