import {
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(() => {
    return {
        tabContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 25,
        },
        imageContainer: {
            display: 'block',
        },
        image: {
            overflow: 'hidden',
            objectFit: 'cover',
            objectPosition: 'center',
            width: 320,
            height: 320,
        },
        controls: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30,
            width: '100%',
            position: 'relative',
        },
    }
})

export default useStyles
